import React, { Component } from "react";
import { getSlug } from "../../utils/getSlug";

export default class DestinationPageListItem extends Component<any, any> {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
          <div className="destinations-card">
            <a className = "destinations-card__container" href={(this.props.isArticle ? "/article/" + this.props.slug : getSlug(this.props.title))}>
            <div className="destinations-card__media">
              <div className="destinations-card__aspect">
              {
                (!this.props.src?.endsWith(".mp4")) &&
              <figure className="destinations-card__asset asset-holder--has-captions asset-holder__image" style={{ backgroundImage: "url('" + this.props.src + "')" }}>
                <img alt={this.props.description} className="asset__image sr-only" src={this.props.src} loading="lazy"
                />
                <figcaption
                  className="asset-holder__image--caption"
                >
                  {this.props.title}
                </figcaption>
              </figure>
              }
              {
                this.props.src?.endsWith(".mp4") &&
                <figure className=" destinations-card__asset  asset-holder__video asset-holder--has-captions" role="group">
                <video aria-label={this.props.title} autoPlay className="asset__video -object-fit" loop muted preload="" playsInline>
                  <source type="video/mp4" src={this.props.src}></source>
                </video>
                <img alt={this.props.description} className="asset__image sr-only" src={this.props.src?.replace(/uploads\//, "uploads/1280/").replace(/[.]mp4/, ".jpg") ?? undefined} loading="lazy"/>
                <figcaption className="asset-holder__image--caption">
                  {this.props.title}
                </figcaption>
                </figure>
              }
              </div>
            </div>
            <div className="destination__headline">  
              <div className="destination__headline">
                <h3 className="h4 destinations-card__title">
                  {this.props.title}
                  <i className="icon icon-circle-arrow" style ={{ height:'auto'}} />
                </h3>
                <div className="destinations-card__subtitle">
                  {this.props.subtitle}
                </div>
              </div>
              <h3>
                  {this.props.description}
              </h3>
            </div>
            </a>
          </div>
    );
  }
}
