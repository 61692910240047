import { Component } from "react";
import { Link } from "react-router-dom";

export default class MissingPage extends Component {
  state = {};
  render() {
    return (
      <main id="main-content" tabIndex={-1} className="BaseWrap-sc-TwdDQ BasePageMainContent-cLpaLc hlNbBe XgDEs page__main-content">
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '70vh', flexDirection: 'column'}}>
          <h1><strong>Page not found</strong></h1><br/>
          <h2>This page does not exist. Have you followed an invalid link?</h2>
          <Link to={"/"} style={{fontSize: "larger", color: "blue", fontWeight: "bold"}}>Go back to Home Page</Link>
        </div>
      </main>

    );
  }
}
