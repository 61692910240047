export const setTitle = (title: string | null) => {
  const wnd = (window as any);
  const SITE_NAME_SHORT = wnd.SITE_NAME_SHORT;
  const SITE_NAME = wnd.SITE_NAME;

  if(!title) {
    document.title = `${SITE_NAME}`;
    return;
  }

  const pathName: string = wnd.location.pathname;
  if(pathName.startsWith("/article/") || pathName.startsWith("/world/")) {
    document.title = `${title} | ${SITE_NAME_SHORT}`;
  }
  else if(pathName.startsWith("/videos")) {
    document.title = `${title} - Video | ${SITE_NAME_SHORT}`;
  }
  else {
    document.title = `${title} | ${SITE_NAME}`;
  }
}

export const getTitleForStaticPage = (): string | null => {
  const wnd = (window as any);
  const PAGE_TITLES: Record<string, string> = wnd.PAGE_TITLES;

  const pathName: string = wnd.location.pathname;

  return pathName in PAGE_TITLES ? PAGE_TITLES[pathName] : null;
}