import React, { Component, createRef } from "react";
import "./stories.css";
import { Article } from "../../app/models/article";
import axios from "axios";

interface StoriesModuleState {
  activeSlide: number;
  width:number;
  articles: Article[];
}


export default class StoriesModule extends Component<{}, StoriesModuleState> {
    wrapperRef: React.RefObject<HTMLDivElement>;
  constructor(props: {}) {
    super(props);
    this.wrapperRef = createRef();

    this.state = {
      activeSlide: 0,
      width: 0,
      articles: []
    };
  }

  handleSlideChange = (index: number) => {
    this.setState({ activeSlide: index });
  };

  
  updateDimensions = () => {
    this.setState({ width: this.wrapperRef.current?.clientWidth! - 40 });
  };

  componentDidMount() {
    this.setState({ width: this.wrapperRef.current?.clientWidth! - 40 });
    window.addEventListener('resize', this.updateDimensions);
    this.fetchFeaturedArticles();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }
  
  fetchFeaturedArticles() {
    const wnd = (window as any);
    const V_FEATURED_ARTICLES_10 = wnd.V_FEATURED_ARTICLES_10;

    axios
    .get("/cache/featured_articles_10.json?" + V_FEATURED_ARTICLES_10)
    .then(response => this.setState({articles: response.data}))
    
    .catch(error => console.log(error));
    };
    
  render() {
    const { activeSlide } = this.state;

    return (
      <section className="stories-module" ref={this.wrapperRef}>
        <div
          className="stories-module__container js-storiesModuleContainer"
          data-init="stories_module_gallery"
        >
          <div className="asset-holder slick-initialized slick-slider slick-dotted">
            <div className="slick-list draggable">
              <div
                className="slick-track"
                style={{
                  opacity: 1,
                  width: this.state.width * this.state.articles.length,
                  transform: `translate3d(${-this.state.width * activeSlide}px, 0px, 0px)`,
                  transition: "transform 0.5s ease",
                }}>
                {this.state.articles.map((item) => 
                <div
                  className="stories-module-slide slick-slide slick-cloned"
                  data-slick-index={-1}
                  aria-hidden={true}
                  style={{ width: this.state.width }}
                  tabIndex={-1}
                  key={item.id}>
                  <div className="stories-module-slide__container">
                    <div className="stories-module-slide__block stories-module-slide__block--media">
                      <div className="stories-module-slide__media">

                      {(!item.imageURL?.endsWith(".mp4")) &&
                        <figure
                          className="asset-holder__image"
                          style={{
                            backgroundImage:
                              'url("' + item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/1280/$1.$2") + '")'
                          }}
                        >
                          <img
                            alt={item.media_description}
                            className="asset__image sr-only"
                            src={item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/1280/$1.$2")}
                            loading="lazy"
                          />
                          <figcaption
                            className="asset-holder__image--caption"
                            id=""
                          ></figcaption>
                        </figure>
                      }
                      {(item.imageURL?.endsWith(".mp4")) &&
                        <figure className="asset-holder__video">
                          <video aria-label={item.title} autoPlay className="asset__video -object-fit" loop muted preload="" playsInline>
                            <source type="video/mp4" src={item.imageURL}></source>
                          </video>
                          <img alt={item.media_description} className="asset__image sr-only" src={item.imageURL.replace(/uploads\//, "uploads/1280/").replace(/[.]mp4/, ".jpg") ?? undefined} loading="lazy"/>
                          <figcaption className="asset-holder__image--caption">
                          </figcaption>
                        </figure>
                    }
                      </div>
                    </div>
                    <div className="stories-module-slide__block">
                      <div className="stories-module-slide__content">
                        <h2 className="stories-module-slide__module-title">
                          Our Stories
                        </h2>
                        <div className="stories-module-slide__headline">
                          <a href={"/article/" + item.slug} tabIndex={-1}>
                            <p className="h5 stories-module-slide__sub-title">{item.subtitle}</p>
                            <h2 className="stories-module-slide__title">
                              {item.title}
                            </h2>
                            <span
                              className="stories-module-slide__link"
                            >
                              Read More
                              <i className="icon icon-circle-arrow" />
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>

            <ul className="slick-dots" style={{}} role="tablist">
              {this.state.articles.map((item, index) => <li
                className={activeSlide === index ? "slick-active" : ""}
                role="presentation"
                key={item.id}
              >
                <button
                  type="button"
                  role="tab"
                  id={"slick-slide-control" + index}
                  aria-controls={"slick-slide" + index}
                  aria-label={(index + 1) + " of " + this.state.articles.length}
                  tabIndex={0}
                  aria-selected={activeSlide === index}
                  onClick={() => this.handleSlideChange(index)}
                >
                  {index + 1}
                </button>
              </li>)}
            </ul>
          </div>
        </div>
      </section>
    );
  }
}
