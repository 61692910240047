import "./destinations-menu.css";
import React, { Component } from "react";
import axios, { AxiosResponse, AxiosError } from "axios";
import { Destination } from "../app/models/destination";
import { Link } from "react-router-dom";
import { setTitle, getTitleForStaticPage } from "../utils/titleHelpers";

interface DestinationsMenuProps {
  destination: Destination;
  destinationType: string;
}

export default class DestinationsMenu extends Component<DestinationsMenuProps, any> {
  state = {
    navbar: false,
    menuVisible: false,
    mobileMenuVisible: false,
    mobileSearchVisible: false,
    destination: new Destination({}),
    destinationType: '',
    destinationsList: [],
    displayedRegionIndex: 0
  };

  constructor(props: DestinationsMenuProps){
    super(props);
    if(props.destination)
      this.state.destination = props.destination;
    if(props.destinationType)
      this.state.destinationType = props.destinationType;
  }

  componentDidMount(): void {
    if(this.state.destination.id === undefined)
      this.loadDestinations();
  }
  
  private loadDestinations() {
    axios.get('/api/destinations?children_count=-1')
      .then((response: AxiosResponse) => {
        this.setState({ destinationsList: response.data });
      })
      .catch((error: AxiosError<any>) => {
        console.log(error);
        if ('message' in error.response?.data)
          alert('Failed to fetch the destination: ' + error.response?.data.message);
      })
      .then(() => { });
  }

  updatePreview = (index: number) => {
    this.setState({displayedRegionIndex: index});
  };

  
  render() {
    setTitle(getTitleForStaticPage());
    return (
<div className="MegaMenuWrapper-bOFuoL FMPIE">
  <div className="GridWrapper-cAzTTK eRggVV grid grid-margins grid-items-0 MegaMenuGridWrapper-kwnjiv iEISii">
    <div className="GridItem-buujkM kHPPIF grid--item">
      <div className="MegaMenuContent-iuNtUF hDFqAx">
        <div className="MegaMenuVerboseWrapper-coDwJl jPwmPa">
          <ul className="MegaMenuVerboseCategoryList-hGBgOM fAPTrG">
            {this.state.destination.id === undefined ?
            this.state.destinationsList.map((x: Destination, index: number) => {
              return (
                <li className={this.state.displayedRegionIndex === index ? "active" : ""}
                onMouseEnter={() => {
                  this.updatePreview(index);
                }}
                key={x.slug}
                >
                    <Link
                      className="MegaMenuVerboseCategoryLink-kpiVee iVHQgw"
                      to={"/world/" + x.slug + "/"}
                    >
                    <span>{x.title}</span>
                    <svg
                      className="icon icon-chevron"
                      focusable="false"
                      viewBox="0 0 32 32"
                      width="1rem"
                      height="1rem"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Chevron</title>
                      <path
                        d="M19.071 16L12 8.929l1.414-1.414L21.9 16l-8.485 8.485L12 23.071z"
                        fillRule="nonzero"
                      />
                    </svg>
                  </Link>
                </li>
                )
              })
            : 
            <li className={"active"}>
                <Link
                  className="MegaMenuVerboseCategoryLink-kpiVee iVHQgw"
                  to={"/world/" + this.state.destination.slug + "/"}
                >
                <span>{this.state.destination.title}</span>
                <svg
                  className="icon icon-chevron"
                  focusable="false"
                  viewBox="0 0 32 32"
                  width="1rem"
                  height="1rem"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Chevron</title>
                  <path
                    d="M19.071 16L12 8.929l1.414-1.414L21.9 16l-8.485 8.485L12 23.071z"
                    fillRule="nonzero"
                  />
                </svg>
              </Link>
            </li>
            }

            {this.state.destination.id !== undefined && <li className="MegaMenuDrawerNavLink-iqsxrC cifiRg">
              <span>
                <Link to="/destinations">All Destinations</Link>
              </span>
            </li>}
          </ul>
          <nav
            aria-labelledby="cities"
            className="NavigationWrapper-bFftAs fKSZRK MegaMenuNavigationPrimary-gUPEii bMrAkp drawer__navigation--primary"
            data-testid="navigation"
          >
            <p
              data-testid="navigation__heading"
              className="NavigationHeadingWrapper-befTuI kSFYXD navigation__heading"
              id="cities"
            >
              {this.state.destination.id === undefined ? "States / Countries" : 
              this.state.destinationType === 'continent' ? "States / Countries" :
              this.state.destinationType === 'country' ? "Cities" : ""}
              <span
                className="content-divider"
                data-testid="navigation__content-divider"
              />
            </p>
            {this.state.destination.id === undefined ?
              this.state.destinationsList.map((x: Destination, index: number) => {
                return (
                  <ul
                    className="NavigationListWrapper-gWXLVS bBdAuQ navigation__list"
                    style={{ 'display': index === this.state.displayedRegionIndex ? "grid" : "none"}}
                    key={'destination-' + x.slug}
                    data-testid="navigation__list"
                  >
                     {x.children !== undefined && x.children.map((y: Destination, index: number) => {
                        return (
                          <li
                          className="NavigationListItemWrapper-cxLZKD iAYgTw navigation__list-item"
                          data-testid="navigation__list-item"
                          key={'destination-item-' + y.slug}
                        >
                          <a
                            className="NavigationInternalLink-cWEaeo knabMb navigation__link"
                            href={"/world/" + x.slug + "/" + y.slug + "/"}
                            data-testid="navigation__internal-link"
                          >
                            {y.fullTitle || y.title}
                          </a>
                        </li>
                        )
                      })}

                  
                  </ul>
                )
              })
            :
            <ul
              className="NavigationListWrapper-gWXLVS bBdAuQ navigation__list"
              style={{ 'display': "grid" }}
              data-testid="navigation__list"
            >
                {this.state.destination.children !== undefined && this.state.destination.children.map((y: Destination, index: number) => {
                  return (
                    <li
                    className="NavigationListItemWrapper-cxLZKD iAYgTw navigation__list-item"
                    data-testid="navigation__list-item"
                    key={'destination-item-' + y.slug}
                  >
                    <a
                      className="NavigationInternalLink-cWEaeo knabMb navigation__link"
                      href={"/world/" + this.state.destination.slug + "/" + y.slug + "/"}
                      data-testid="navigation__internal-link"
                    >
                      {y.fullTitle || y.title}
                    </a>
                  </li>
                  )
                })}            
            </ul>
            }
             
          </nav>
          <span className="NavigationDivider-fEDnwq ixXXMs navigation__divider" />
        </div>
        
      </div>
    </div>
  </div>
</div>
    );
  }
}
