/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, createRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { ArticlePageState } from "./articlePageState";
import { Article } from "../../app/models/article";
import { Video } from "../../app/models/video";

export default class ArticleComponent extends Component<any, any> {

  state: ArticlePageState = {
    article: new Article(),
    showModal: false,
    slug: '',
    featuredVideo: new Video(null),
    featuredArticles: new Array<Article>(),
    readMoreArticles: new Array<Article>()
  };
  wrapperRef: React.RefObject<HTMLDivElement>;
  closeButtonRef: React.RefObject<HTMLButtonElement>;
  modalTimeout?: NodeJS.Timeout;

  constructor(props: any) {
    super(props);
    this.handleModal = this.handleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.wrapperRef = createRef();
    this.closeButtonRef = createRef();

    this.state.slug = props.Slug;
    this.state.article = props.Article;
    this.state.featuredVideo=props.FeaturedVideo;
    this.state.featuredArticles=props.FeaturedArticles;
    this.state.readMoreArticles=props.ReadMoreArticles;

  }

  componentDidMount() {
    this.modalTimeout = setTimeout(() => {
      this.handleModal()
    }, 10000);
  }

  componentWillUnmount(): void {
    this.modalTimeout && clearInterval(this.modalTimeout);
  }

  handleModal() {
    this.setState({ showModal: true });
  }

  closeModal(event: any) {
    if (
      (this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)) ||
      (this.closeButtonRef.current &&
        this.closeButtonRef.current === event.target)
    ) {
      this.setState({ showModal: false });
    }

  }

}