import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import { nl2br } from "../utils/nl2br";

interface ArticleGridItemState {
  ImageSrc: string;
  ImageSrc400: string;
  ImageSrc800: string;
  ImageSrc1280: string;
  Title: string;
  ArticleLink: string;
  MediaDescription: string;
}

export default class ArticleGridItem extends Component<any, any> {
  state: ArticleGridItemState;

  constructor(props: any) {
    super(props);

    this.state = {
      ImageSrc: props.ImageSrc,
      ImageSrc400: props.ImageSrc.replace(/uploads\//, "uploads/400/"),
      ImageSrc800: props.ImageSrc.replace(/uploads\//, "uploads/800/"),
      ImageSrc1280: props.ImageSrc.replace(/uploads\//, "uploads/1280/"),
      Title: nl2br(props.Title),
      ArticleLink: "article/" + props.Slug,
      MediaDescription: props.MediaDescription
    };
  }

  render() {
    return (
      <div className="square-grid-card">
        <div className="square-grid-card__container">
          <div className="square-grid-card__aspect">
            <div className="square-grid-card__background">
              <div className="square-grid-card__asset">
                {this.state.ImageSrc.endsWith(".mp4") ? (
                  <video loop autoPlay muted playsInline style={{
                    'width': '100%',
                    'height': '100%',
                    'objectFit': 'cover'
                  }}>
                    <source src={this.state.ImageSrc} type="video/mp4" />
                  </video>
                ) : (
                  <picture className="square-grid-card__background__asset asset-holder__image">
                    <source
                      media="(max-width: 767px)"
                      srcSet={
                        this.state.ImageSrc400 +
                        " 400w, " +
                        this.state.ImageSrc800 +
                        " 800w"
                      }
                      sizes="100vw"
                    ></source>
                    <source
                      media="(min-width: 768px) and (max-width: 1919px)"
                      srcSet={this.state.ImageSrc1280}
                      sizes="100vw"
                    ></source>
                    <source
                      media="(min-width: 1920px)"
                      srcSet={this.state.ImageSrc1280}
                      sizes="100vw"
                    ></source>
                    <img
                      alt={this.state.MediaDescription}
                      className="responsive-image__image"
                      src={this.state.ImageSrc1280}
                      loading="lazy"
                    />
                  </picture>
                )}
              </div>
            </div>
            <div className="square-grid-card__gradient"></div>
            <div className="square-grid-card__foreground">
              <Link
                to={this.state.ArticleLink}
                className="square-grid-card__content"
              ></Link>
              <div className="square-grid-card__headline">
                <h3 className="h2 square-grid-card__title">
                  <Link
                    to={this.state.ArticleLink}
                    className="square-grid-card__content"
                  >
                    {this.state.Title}
                  </Link>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
