/* eslint-disable react-hooks/rules-of-hooks */
import { Component } from "react";
import { Article } from "../../app/models/article";
import { ArticlePageState } from "./articlePageState";
import ArticleTemplate1 from "./articleTemplate1";
import ArticleTemplate2 from "./articleTemplate2";
import { Video } from "../../app/models/video";
import axios, { AxiosResponse, AxiosError } from "axios";
import { withRouter } from "react-router-dom";
import { setTitle } from "../../utils/titleHelpers";

export class ArticlePage extends Component<any, any> {

  state: ArticlePageState = {
    article: new Article(),
    showModal: false,
    slug: '',
  };

  constructor(props: any) {
    super(props);

    var route = this.props.match.params;

    if('slug' in route) {
      this.state.slug = route.slug;
    }
  }

  componentDidMount() {
    this.fetchArticle();
    this.fetchFeaturedVideo();
    this.fetchFeaturedArticles();
    this.fetchReadMoreArticles();
    
  }
  fetchReadMoreArticles() {
    axios
    .get("/api/articles/global/readMoreArticles/" + this.state.slug)
    .then(response => this.setState({readMoreArticles:response.data}))
    
    .catch(error => console.log(error));
    };
    
  fetchFeaturedArticles() {
      const wnd = (window as any);
      const V_FEATURED_ARTICLES_3 = wnd.V_FEATURED_ARTICLES_3;

      axios
      .get("/cache/featured_articles_3.json?" + V_FEATURED_ARTICLES_3)
      .then(response => this.setState({featuredArticles:response.data}))
      
      .catch(error => console.log(error));
      };
    
  fetchFeaturedVideo() {
      const wnd = (window as any);
      const V_FEATURED_VIDEO = wnd.V_FEATURED_VIDEO;

      axios
      .get("/cache/featured_video.json?" + V_FEATURED_VIDEO)
      .then(response => this.setState({featuredVideo: new Video(response.data)}))
      
      .catch(error => console.log(error));
  };
    

  private fetchArticle() {
    axios.get('/api/articles/' + this.state.slug)
      .then((response: AxiosResponse) => {
        this.setState({ article: response.data }, () => {
          setTitle(this.state.article.title);
        });
      })
      .catch((error: AxiosError<any>) => {
        console.log(error);
        if ('message' in error.response?.data)
          alert('Failed to fetch the article: ' + error.response?.data.message);
      })
      .then(() => { });
  }

  render() {
    
    return this.state.article.id != null && this.state.featuredArticles !=null && this.state.featuredVideo !=null && this.state.readMoreArticles !=null && (
      this.state.article.type === 1 ?
      <ArticleTemplate1
        Slug={this.state.slug}
        Article={this.state.article}
        FeaturedVideo={this.state.featuredVideo!}
        FeaturedArticles={this.state.featuredArticles!}
        ReadMoreArticles={this.state.readMoreArticles!}

         />
      :
      <ArticleTemplate2
        Slug={this.state.slug}
        Article={this.state.article}
        FeaturedVideo={this.state.featuredVideo!} 
        FeaturedArticles={this.state.featuredArticles!}
        ReadMoreArticles={this.state.readMoreArticles!}
        />)
  }
}

export default withRouter(ArticlePage);