import axios, { AxiosResponse, AxiosError } from "axios";
import { Component } from "react";
import { Article } from "../app/models/article";
import DestinationsGroup from "./destinations/destinationsGroup";
import Pagination from "./pagination";
import { setTitle, getTitleForStaticPage } from "../utils/titleHelpers";

let PageSize = 6;

export default class AdventureTravelPage extends Component {
  state = {
    articles: new Array<Article>(),
    currentPageArticles: new Array<Article>(),
    currentPage: 1,
  };
  updateListItems() {
    const firstPageIndex = (this.state.currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    var currentItems = this.state.articles.slice(
      firstPageIndex,
      lastPageIndex
    );
    this.setState({ currentPageArticles: currentItems });
    }
  componentDidMount() {
    axios
      .get('/api/articles/category/adventure-travel', {
        params: {
          status: 1
        }
      })
      .then((response: AxiosResponse) => {
        this.setState({articles: response.data}, () => {
          this.updateListItems();
        });

      })
      .catch((error: AxiosError<any>) => {
        console.log(error);
        if('message' in error.response?.data)
          alert('Failed to fetch the article: ' + error.response?.data.message);
      })
      .then(() => { });
  }
  
  render() {
    setTitle(getTitleForStaticPage());
    return (
      <>
        <section className="showcaseDestination">
          {
            <img src={"https://www.myglobalviewpoint.com/wp-content/uploads/2020/11/Best-Airbnbs-in-the-World-Featured-Image.jpg"} alt="" />
          }
          <div className="text">
          <div style={{textAlign:"center"}}>
                <div className="arcticle-grid-title">
                  <h3 className="h3">Adventure Travel</h3>
                </div>
                <h4>
                  Hundreds of one-of-a-kind experiences beckon at Vacation Muse
                  Collection hotels, resorts and residences.
                </h4>
              </div>
          </div>
        </section>
        <div className="mx2">
          <div className="article-grid">
            <div className="square-grid__holder">
              <div className="square-grid__items"></div>
              <div className="square-grid-body">
                 <DestinationsGroup
                    List={this.state.currentPageArticles}
                    DisplayChildren={true} />
              </div>
            </div>
          </div>
        </div>
        <Pagination
            className="pagination-bar"
            currentPage={this.state.currentPage}
            totalCount={this.state.articles.length}
            pageSize={PageSize}
            onPageChange={(page: any) => {
              this.setState({ currentPage: page }, this.updateListItems);
            }}
          />
      </>
    );
  }
}
