import React, { Component } from 'react'
import { nl2br } from '../../utils/nl2br';

interface VideoTitleSettings {
    homepage_header_video?: string;
    homepage_header_video_title?: string;
}

interface VideoTitleState {
    settings?: VideoTitleSettings;
}

export default class VideoTitle extends Component<any, VideoTitleState> {
    componentDidMount() {
        this.fetchSettings();
    }

    fetchSettings() {
        const wnd = (window as any);
        const HOMEPAGE_HEADER_VIDEO_SETTINGS = wnd.HOMEPAGE_HEADER_VIDEO_SETTINGS;
        if(HOMEPAGE_HEADER_VIDEO_SETTINGS && Object.keys(HOMEPAGE_HEADER_VIDEO_SETTINGS).length) {
          this.setState({ settings: HOMEPAGE_HEADER_VIDEO_SETTINGS });
          return;
        }
    }

    state: VideoTitleState = {
        settings: {}
    }

    render() {
        return (
            <section className="showcase">
                <video src={this.state.settings?.homepage_header_video} muted loop autoPlay playsInline></video>
                <div className="text">
                    <h2 dangerouslySetInnerHTML={{ __html: this.state.settings?.homepage_header_video_title?.replaceAll(/\n/g, '<br />') ?? ''}}></h2>
                </div>
            </section>
        );
    }
}