import React, { Component } from "react";
import ArticleGrid from "./articleGrid";
import DestinationList from "./destinations/destinationList";
import LandingVideo from "./landingVideo";
import VideoTitle from "./videos/videoTitle";
import StoriesModule from "./stories/stories";
import { getTitleForStaticPage, setTitle } from "../utils/titleHelpers";

export default class HomePage extends Component {
  state = {};
  render() {
    setTitle(getTitleForStaticPage());
    return (
      <div id="homeContent">
        <VideoTitle />
        <ArticleGrid />
        <DestinationList />
        <LandingVideo />
        <StoriesModule />
    </div>
    );
  }
}
