import { CityArticle } from "./cityarticle";

export class Destination {
    id: number;
    title: string;
    fullTitle?: string;
    slug: string;
    description: string;
    children?: Array<Destination>;
    articles?: undefined | Array<CityArticle>;
    imageURL?: string;
    
    constructor(props: any){
      this.id = props.id;
      this.title = props.title;
      this.fullTitle = props.title ?? null;
      this.slug = props.slug ?? null;
      this.description = props.description;
      this.children = props.children;
      this.articles = props.articles;
    }
  }