import React, { Component, Ref } from "react";
import { Menu } from "semantic-ui-react";
import "font-awesome/css/font-awesome.min.css";

import { Link } from "react-router-dom";
import DropdownGroup from "./dropdownGroup";
import { OutsideClickHandler } from "./outsideClickHandler";
import { Destination } from "../../app/models/destination";
import axios, { AxiosResponse, AxiosError } from "axios";

export default class NavBar extends Component {
  state = {
    navbar: false,
    menuVisible: false,
    mobileMenuVisible: false,
    mobileSearchVisible: false,
    destinationsList: [],
    displayedRegionIndex: 0
  };

  handleItemClick = (e: any, { name }: any) =>
    this.setState({ activeItem: name });

  toggleDestinationsMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
    this.setState({ menuVisible: !this.state.menuVisible, navbar: true });
    e.preventDefault();
  };

  handleOutsideClick() {
    if(this.state.menuVisible){
        this.setState({menuVisible: false})
    }
  }

  toggleMobileMenu = (e: any) => {
    var newState = !this.state.mobileMenuVisible;

    document.body.classList.toggle('main-nav-mobile', newState);
    setTimeout(x=>this.setState({ mobileMenuVisible: newState }), 0);
  };

  toggleMobileSearch = (e: any) => {
    var newState = !this.state.mobileSearchVisible;

    this.setState({ mobileSearchVisible: newState }, () => {
      if(newState) {
        this.inputFocus.setFocus();
      }
    });
  };
  inputFocus: {ref: Ref<HTMLInputElement>, setFocus: Function};

  constructor(props: any){
    super(props);
    this.inputFocus = this.utilizeFocus();
  }

  componentDidMount(): void {
    this.registerGlobalEvents();
    this.loadDestinations();
  }

  utilizeFocus = () => {
    const ref: Ref<HTMLInputElement> = React.createRef()
    const setFocus = () => {ref.current && ref.current.focus()}

    return {setFocus, ref}
  }

  private registerGlobalEvents() {
    const changeBackground = () => {
      if (window.scrollY >= 50 || ["/unique-hotels", "/adventure-travel", "/cruises"].includes(window.location.pathname)) {
        this.setState({ navbar: true });
      } else {
        if (!this.state.menuVisible)
          this.setState({ navbar: false });
      }
    };
    const changeBackgroundAlternate = () => {
      if (["/unique-hotels", "/adventure-travel", "/cruises"].includes(window.location.pathname)) {
        this.setState({ navbar: true });
      }
    };
    window.addEventListener("scroll", changeBackground);
    window.addEventListener('load', changeBackgroundAlternate);
  }

  private loadDestinations() {
    const wnd = (window as any);
    const V_DESTINATIONS = wnd.V_DESTINATIONS;

    axios.get('/cache/destinations.json?' + V_DESTINATIONS)
      .then((response: AxiosResponse) => {
        this.setState({ destinationsList: response.data });
      })
      .catch((error: AxiosError<any>) => {
        console.log(error);
        if ('message' in error.response?.data)
          alert('Failed to fetch the destination: ' + error.response?.data.message);
      })
      .then(() => { });
  }

  updatePreview = (index: number) => {
    this.setState({displayedRegionIndex: index});
  };

  render() {
    const { activeItem }: any = this.state;

    return (
      <OutsideClickHandler onOutsideClick={(e: any) => this.handleOutsideClick()}>

      <div id="menuWrapper" className={(this.state.navbar || this.state.mobileSearchVisible) ? "menu-bg" : ""}>
 
        <span id="main-nav">

        <div className="main-nav-mobile-toggle" onClick={this.toggleMobileMenu}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
        <Link to="/" className="main-nav-link-logo main-nav-home">
          <img
            src="/images/logo4d.svg"
            alt="Logo"
            className="logo"
          />
        </Link>
        <form action="/search">
          <div className="search-wrapper">
            <i className="fa fa-search"></i>
            <input
              type="text"
              className="search-box"
              name="search"
              placeholder="Search cities, hotels..."
              autoComplete="off"
            />
          </div>
        </form>

        <Menu>
          <Menu.Item
            as={Link}
            to="/destinations"
            name="destinations"
            active={activeItem === "destinations"}
            onClick={this.toggleDestinationsMenu}
          >
            Destinations
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/unique-hotels"
            name="unique-hotels"
            active={activeItem === "unique-hotels"}
            onClick={this.handleItemClick}
          >
            Unique Hotels
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/adventure-travel"
            name="adventure-travel"
            active={activeItem === "adventure-travel"}
            onClick={this.handleItemClick}
          >
            Adventure Travel
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/videos"
            name="videos"
            active={activeItem === "videos"}
            onClick={this.handleItemClick}
          >
            Videos
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/cruises"
            name="cruises"
            active={activeItem === "cruises"}
            onClick={this.handleItemClick}
          >
            Cruises
          </Menu.Item>

          <Menu.Item
            as={Link}
            to="/subscribe"
            name="subscribe"
            active={activeItem === "subscribe"}
            onClick={this.handleItemClick}
          >
            Subscribe
          </Menu.Item>
        </Menu>

        <div className="main-nav-mobile-search" onClick={this.toggleMobileSearch}>
          <i className={`fa fa-${this.state.mobileSearchVisible ? "times" : "search"}`} aria-hidden="true"></i>
        </div>

        </span>
       
        <div
          id="main-nav-flyout"
          className={this.state.menuVisible ? "show" : ""}
        >
          <div
            className="main-nav-flyout-destinations show"
            data-id="destinations"
          >
            <div className="main-nav-flyout-wrapper">
              <div className="main-nav-flyout-destinations-sidebar">
                {this.state.destinationsList.map((x: Destination, index: number) => {
                  return (
                    <a
                      href={"/world/" + x.slug + "/"}
                      data-id={x.slug}
                      key={x.slug}
                      className={this.state.displayedRegionIndex === index ? "hover" : ""}
                      onMouseEnter={() => {
                        this.updatePreview(index);
                      }}
                    >
                      {x.title}
                    </a>
                  )
                })}
              </div>
              <div className="main-nav-flyout-destinations-main">
                {this.state.destinationsList.map((x: Destination, index: number) => {
                  return (
                    <div className={`main-nav-flyout-destinations-${x.slug} ${index === this.state.displayedRegionIndex ? "show" : ""}`} key={x.slug}>
                    <p>Top Destinations In {x.fullTitle || x.title}</p>
                    <div className="main-nav-flyout-destinations-top">
                      <div className="main-nav-flyout-destinations-top-primary">
                        {x.children !== undefined && x.children.slice(0,3).map((y: Destination, index: number) => {
                          return (
                          <a
                            key={y.id}
                            href={"/world/" + x.slug + "/" + y.slug + "/"}
                            style={{
                              backgroundImage:
                                "url('" + y.imageURL?.replace(/uploads/, 'uploads/400')  + "')",
                            }}
                          >
                            <span>{y.fullTitle || y.title}</span>
                          </a>)
                        })}
                      </div>
                      <div className="main-nav-flyout-destinations-top-secondary">
                    
                     {
                      x.children !==undefined && x.children.length>3 &&  <span>
                      Other states/countries
                    </span>
                    }
                        {x.children !== undefined && x.children.slice(3,x.children.length).map((y: Destination, index: number) => {
                          return (
                          <a
                            key={y.id}
                            href={"/world/" + x.slug + "/" + y.slug + "/"}
                          >
                            <span>{y.fullTitle || y.title}</span>
                          </a>)
                        })
                        }
                        
                        {
                          x.children !==undefined && x.children.length>3 &&
                          <a
                            href={`/world/${x.slug}/`}
                            className="main-nav-flyout-destinations-top-secondary-all"
                          >
                            View All {x.title}
                          </a>
                        }
                       
                      </div>
                    </div>
                    <a href={"/destinations"} className="main-nav-flyout-destinations-all">
                      View All Destinations
                    </a>
                  </div>
                  )
                })}
              </div>
              <div className="clearfix"></div>
            </div>
          </div>

          <div className="main-nav-flyout-close" onClick={() => this.setState({menuVisible: false})}></div>
        </div>
      
        <div id="main-nav-flyout-mobile-search" className={this.state.mobileSearchVisible ? "focus": ""}>
          <div className="main-nav-search" data-id="search">
            <form action="/search" className="site-search" method="GET">
              <i className="fa fa-search" aria-hidden="true" />
              <input type="text" name="search" placeholder="Search cities, hotels…" id="search-q-mobile" ref={this.inputFocus.ref} />
              <button type="submit" className="main-nav-search-submit"><i className="fa fa-long-arrow-right fa-rotate-315" aria-hidden="true" />Go</button>
              <div className="clearfix" />
            </form>
          </div>
        </div>
        
        <div id="main-nav-mobile">
          <div className="main-nav-mobile-links">
            <a href="/">Home</a>
            {!!this.state.destinationsList.length && <DropdownGroup Title="Destinations" List={this.state.destinationsList} />}
            <a href="/unique-hotels">Unique Hotels</a>
            <a href="/adventure-travel">Adventure Travel</a>
            <a href="/videos">Videos</a>
            <a href="/cruises">Cruises</a>
          </div>
          <hr />
          <div className="main-nav-mobile-login">
            <a href="/login"><i className="fa fa-user-circle-o" aria-hidden="true" />Login</a>
          </div>
          <div className="main-nav-mobile-subscribe">
            <a href="/subscribe"><i className="fa fa-envelope" aria-hidden="true" />Subscribe</a>
          </div>
        </div>
        <div id="main-nav-mobile-close" className={this.state.mobileMenuVisible ? "slide-in" : ""} onClick={this.toggleMobileMenu}><div className="dot" /><i className="fa fa-times" aria-hidden="true" /></div>

      </div>

      </OutsideClickHandler>
    );
  }
}
