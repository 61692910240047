import React, { Component, createRef } from "react";


type OutsideClickHandlerProps = {
    onOutsideClick?: Function;
    children?: React.ReactNode; // 👈️ added type for children
};

export class OutsideClickHandler extends Component {

    wrapperRef: React.RefObject<HTMLDivElement>;
    props: OutsideClickHandlerProps;
    
    constructor(props: OutsideClickHandlerProps){
        super(props);
        this.props = props;
        this.wrapperRef = createRef();
    }

    componentDidMount() {
      document
        .addEventListener('mousedown', this.handleClickOutside);
    }
  
    componentWillUnmount(){
      document
        .removeEventListener('mousedown', this.handleClickOutside);
    }
  
    handleClickOutside = (event: any) => {
      if (
        this.wrapperRef.current &&
        !this.wrapperRef.current.contains(event.target)
      ) {
        this.props.onOutsideClick!();
      }
    }

    render() {    
        return (
        <div className="outsideClickHandlerWrapper" ref={this.wrapperRef}>
            {this.props.children}
        </div>
        )
    }  
  }