import React from "react";
import ReactDOM from 'react-dom/client';
import "./app/layout/styles.css";
import "./app/layout/main-nav.css";
import App from "./app/layout/App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";

const LOCAL_DOMAINS = [ "localhost", "127.0.0.1" ];

if ( LOCAL_DOMAINS.includes(window.location.hostname) )
{
    axios.defaults.baseURL = 'https://vacationmuse.com/admin';
}
else
{
  axios.defaults.baseURL = 'https://' + window.location.hostname + '/admin';
}

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
