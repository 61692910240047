import React, { Component } from "react";
import DestinationPageListItem from "./destinationPageListItem";
import Pagination from "../pagination";

let PageSize = 6;

export default class DestinationPageList extends Component<any, any> {
  state = {
    destinationPageItems: Array<{
      id: number;
      title: string;
      description: string;
      imageURL: string;
      subtitle:string;
      slug: string;
    }>(),
    currentPageItems: Array<{
      id: number;
      title: string;
      description: string;
      imageURL: string;
      subtitle:string;
      slug:string;
    }>(),
    currentPage: 1,
    horizontal: false,
    articles: false,
  };

  constructor(props: any) {
    super(props);

    console.log(props);
    this.state.horizontal = props.Horizontal;
    if(props.Articles?.length) {
      this.state.destinationPageItems = props.Articles;
      this.state.articles = true;
    }
    else if(props.Destinations?.length) {
      this.state.destinationPageItems = props.Destinations;
    }
  }

  updateListItems() {
    const firstPageIndex = (this.state.currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    var currentItems = this.state.destinationPageItems.slice(
      firstPageIndex,
      lastPageIndex
    );

    this.setState({ currentPageItems: currentItems });
  }

  componentDidMount() {
    this.updateListItems();
  }

  componentWillUnmount() {}

  render() {
    return (
      <div aria-expanded = "true" className="destinations-group">
        
        <div className={this.state.horizontal ? "destinations__grid" : ""}>
          {this.state.currentPageItems.map((object) => (
            <DestinationPageListItem
              key={object.id}
              title={object.title}
              description={object.description}
              subtitle={object.subtitle}
              src={object.imageURL}
              slug={object.slug}
              isArticle={this.state.articles}
            />
          ))}

        </div>
        
        <Pagination
            className="pagination-bar"
            currentPage={this.state.currentPage}
            totalCount={this.state.destinationPageItems.length}
            pageSize={PageSize}
            horizontal={this.state.horizontal}
            onPageChange={(page: any) => {
              this.setState({ currentPage: page }, this.updateListItems);
            }}
          />
      </div>
    );
  }
}
