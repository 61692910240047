import { Component } from "react";
import { getSlug } from "../../utils/getSlug";
import Moment from 'moment';
import { Article } from "../../app/models/article";
import ConditionalLink from "../general/conditionalLink";

export default class DestinationsGroupItem extends Component<any, any> {
  state = {
    item: new Article()
  };

  constructor(props: any) {
    super(props);

    this.state = {
      item: props.Obj,
    };

  }

  render() {

    return <>
      <div className="destinations-card" key={this.state.item.id}>
        <ConditionalLink
          className="destinations-card__container"
          to={"/article/" + getSlug(this.state.item.title)}
          condition={this.state.item.status === 1}
        >
          <div className="destinations-card__media">
            <div className="destinations-card__aspect">
              {
                (!this.state.item.imageURL.endsWith(".mp4")) &&
              <figure className=" destinations-card__asset asset-holder--has-captions asset-holder__image" style={{ backgroundImage: "url('" + this.state.item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/800/$1.$2") + "')" }}>
                <img alt={this.state.item.media_description} className="asset__image sr-only" src={this.state.item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/800/$1.$2")} loading="lazy"
                />
                <figcaption
                  className="asset-holder__image--caption"
                >
                  {this.state.item.subtitle}
                </figcaption>
              </figure>
              }
              {
                this.state.item.imageURL.endsWith(".mp4") &&
                <figure className=" destinations-card__asset  asset-holder__video asset-holder--has-captions" role="group">
                <video aria-label={this.state.item.title} autoPlay className="asset__video -object-fit" loop muted preload="" playsInline>
                  <source type="video/mp4" src={this.state.item.imageURL}></source>
                </video>
                <img alt={this.state.item.media_description} className="asset__image sr-only" src={this.state.item.imageURL.replace(/uploads\//, "uploads/800/").replace(/[.]mp4/, ".jpg") ?? undefined} loading="lazy"/>
                <figcaption className="asset-holder__image--caption">
                  {this.state.item.subtitle}
                </figcaption>
                </figure>
              }

              <div className="destinations-card__badge h5">
                {this.state.item.created_at !== undefined && Moment(this.state.item.created_at).format('Y')}
              </div>
            </div>
          </div>
          <div className="destination__headline">
            <h3 className="h4 destinations-card__title">
              {this.state.item.title}
              {this.state.item.status === 1 && <i className="icon icon-circle-arrow" style ={{ height:'auto'}}></i>}
            </h3>
            <div className="destinations-card__subtitle">
              {this.state.item.subtitle}
            </div>
          </div>
        </ConditionalLink>
      </div>
    </>
  }
}
