import { Component } from "react";
import DestinationsGroup from "./destinationsGroup";
import { Article } from "../../app/models/article";
import axios from "axios";

interface DestinationListSettings {
  homepage_destinations_title?: string;
  homepage_destinations_subtitle?: string;
}

interface DestinationListDestination {
  id: number;
  description: string;
  destination_id: number;
  ord: number;
  destination: {
    title: string;
  };
  articles: Array<Article>;
}

interface DestinationListState {
  destinations: Array<DestinationListDestination>;
  settings?: DestinationListSettings;
}

export default class DestinationList extends Component<any, DestinationListState> {
  state: DestinationListState = {
    destinations: Array<DestinationListDestination>(),
  };

  fetchSettings() {
    const wnd = (window as any);
    const HOMEPAGE_DESTINATIONS_SETTINGS = wnd.HOMEPAGE_DESTINATIONS_SETTINGS;
    if(HOMEPAGE_DESTINATIONS_SETTINGS && Object.keys(HOMEPAGE_DESTINATIONS_SETTINGS).length) {
      this.setState({ settings: HOMEPAGE_DESTINATIONS_SETTINGS });
      return;
    }
}

  loadDestinations() {
    const wnd = (window as any);
    const V_HOMEPAGE_DESTINATIONS = wnd.V_HOMEPAGE_DESTINATIONS;

    axios
      .get("/cache/homepage_destinations.json?" + V_HOMEPAGE_DESTINATIONS)
      .then((response) => this.setState({ destinations: response.data }))

      .catch((error) => console.log(error));
  }

  componentDidMount() {
    this.fetchSettings();
    this.loadDestinations();
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="mx2">
        <div className="destinations mb--md">
          <div className="destinations__container">
            <div className="destinations__headline">
              <div className="destinations__title">
                <h5 className="h5 gray-line" dangerouslySetInnerHTML={{__html: this.state.settings?.homepage_destinations_title ?? ''}}>
                  
                </h5>
              </div>
              <h4 className="h3" dangerouslySetInnerHTML={{__html: this.state.settings?.homepage_destinations_subtitle ?? ''}}>
              </h4>
            </div>
            <div className="destinations__list">
              {this.state.destinations.map((object) => (
                <DestinationsGroup
                  key={object.id}
                  Title={object.destination.title}
                  Description={object.description}
                  List={object.articles}
                  ShowHide={true}
                  DisplayChildren={true}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
