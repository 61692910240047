import Footer from "../../components/footer";
import NavBar from "../../components/navbar/navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ArticlePage from "../../components/articles/articlePage";
import Subscribe from "../../components/subscribe";
import AppContainer from "../../components/appContainer";
import DestinationPage from "../../components/destinations/destinationPage";
import VideosPage from "../../components/videos/videosPage";
import Search from "../../components/search/search";
import CruisesPage from "../../components/cruisesPage";
import UniqueHotelsPage from "../../components/uniqueHotelsPage";
import AdventureTravelPage from "../../components/adventureTravelPage";
import HomePage from "../../components/homePage";
import MissingPage from "../../components/missingPage";
import DestinationsMenu from "../../components/destinations-menu";

function App(props: any) {
  return (
    <Router>
      <AppContainer>
        <NavBar />
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/unique-hotels" component={UniqueHotelsPage} />
          <Route path="/destinations" component={DestinationsMenu} />
          <Route path="/adventure-travel" component={AdventureTravelPage} />
          <Route path="/cruises" component={CruisesPage} />
          <Route path="/videos/:video" component={VideosPage}/>
          <Route path="/videos" component={VideosPage} />
          <Route path="/subscribe" component={Subscribe} />
          <Route path="/article/:slug" component={ArticlePage} />
          <Route path="/search" component={Search} />
          <Route path="/world/:continent/:country/:city/" component={DestinationPage}/>
          <Route path="/world/:continent/:country/" component={DestinationPage}/>
          <Route path="/world/:continent/" component={DestinationPage}/>
          <Route component={MissingPage} />
        </Switch>
        <Footer />
      </AppContainer>
    </Router>
  );
}

export default App;
