import { Component } from "react";
import $ from 'jquery';
import { CityArticle } from "../../app/models/cityarticle";
import DestinationsGroupItem from "./destinationsGroupItem";

export default class DestinationsGroup extends Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = {
      title: props.Title,
      description: props.Description,
      destinations: props.List || props.Articles,
      duration: 450,
      animating: false,
      expanded: true,
      displayChildren: props.DisplayChildren,
      showHide: props.ShowHide || false
    };
  }

  init = (e: any, callback: any) => {
    const parent = $(e).closest('.destinations-group');

    this.setState({
      mod: parent,
      actions: parent.find(".destinations-group__trigger"),
      content: parent.find(".destinations-group__body"),
      animating: true
    }, callback)
  }

  toggle = (e: any) => {
    if(!this.state.showHide)
      return;

    if (!this.state.animating){
      var l = this;
      this.init(e.currentTarget, function(){
        if(l.state.expanded)
          l.close()
        else
          l.open()
      });
    }
  }

  close = () => {
    this.state.content.slideUp({
        duration: this.state.duration
    });

    this.state.mod.attr("aria-expanded", "false");
    this.setState({expanded: false});
  
    var l = this;
    setTimeout((
      function() {
          l.setState({animating: false});
      }), this.state.duration);
 
  }

  open = () => {
    this.state.content.slideDown({
        duration: this.state.duration
    });
    this.state.mod.attr("aria-expanded", "true");
    this.setState({expanded: true});
 
    var l = this;
    setTimeout(
    function() {
       l.setState({animating: false});
    }, this.state.duration);
  }

  render() {
    let destinations=this.props.List || this.props.Articles;
    return <div
      aria-expanded="true"
      className="destinations-group"
      data-load="DestinationsGroup"
    >
      

      {this.state.showHide &&
      <div className="destinations-group__header">
        <button className="destinations-group__trigger" onClick={this.toggle}>
            <span className="h3 destinations-group__title">
              {this.state.title}
              <span className="destinations-group__indicator"></span>
            </span>
          </button>
      </div>
      }
      <div className="destinations-group__body">
        {this.state.description &&
        <div className="destinations-group__content">
          <div className="destinations-group__description">
            <p className="h4">
            {this.state.description}
            </p>
          </div>
        </div>}
        <div className="destinations__grid">

          {
            this.state.displayChildren &&
            destinations.map((destination: CityArticle) => {
              return (
                <DestinationsGroupItem 
                 key={destination.id}
                 Obj={destination}
                />
              )
            })
          }


        </div>
      </div>
    </div>
  }
}
