export class Video {
    id?: number;
    title?: string;
    slug?: string;
    description?: string;
    source?: string;
    thumbnail?: string;
    video_url?: string;
    constructor(props: any){
      if(props != null){
        this.id = props.id;
        this.title = props.title;
        this.slug = props.slug;
        this.description = props.description;
        this.source = props.source;
        this.thumbnail = props.thumbnail;
        this.video_url=props.video_url;
      }
    }
  
  }