import React from "react"

export const nl2br = (string: any) => {
    return string.split('\n').map((item: any, index: any) => {
      return (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      )
    });
  }