import React, { Component } from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Article } from "../app/models/article";
import ArticleGridItem from "./articleGridItem";
import axios, { AxiosResponse, AxiosError } from "axios";


interface ArticleGridSettings {
  homepage_article_carousel_title?: string;
  homepage_article_carousel_subtitle?: string;
}

interface ArticleGridState {
  articles: Array<Article>;
  settings?: ArticleGridSettings;
}

export default class ArticleGrid extends Component {
  
  state: ArticleGridState = {
    articles: new Array<Article>()
  };

  responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  
  };

  fetchSettings() {
      const wnd = (window as any);
      const HOMEPAGE_ARTICLE_CAROUSEL_SETTINGS = wnd.HOMEPAGE_ARTICLE_CAROUSEL_SETTINGS;
      if(HOMEPAGE_ARTICLE_CAROUSEL_SETTINGS && Object.keys(HOMEPAGE_ARTICLE_CAROUSEL_SETTINGS).length) {
        this.setState({ settings: HOMEPAGE_ARTICLE_CAROUSEL_SETTINGS });
        return;
      }
  }

  componentDidMount() {
    this.fetchSettings();
    
    const wnd = (window as any);
    const V_RANDOM_ARTICLES_15 = wnd.V_RANDOM_ARTICLES_15;

    axios
      .get('/cache/random_articles_15.json?' + V_RANDOM_ARTICLES_15)
      .then((response: AxiosResponse) => {
        this.setState({articles: response.data});
      })
      .catch((error: AxiosError<any>) => {
        console.log(error);
        if('message' in error.response?.data)
          alert('Failed to fetch the article: ' + error.response?.data.message);
      })
      .then(() => { });
  }

  render() {
    return (
      <div className="mx2">
        <div className="article-grid">
          {/* <div className="custom-label-wrapper">
            <div className="custom-group-center">
              <div className="custom-group-actions">
                <div className="custom-group-action">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Follow"
                    className="btn-round"
                    href="https://www.instagram.com/"
                  >
                    Follow
                    <i className="icon-arrow-right" style ={{ height:'auto'}}></i>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <div className="square-grid__holder">
            <div className="square-grid__items"></div>
            <div className="article-grid-headline">
              <div className="arcticle-grid-title">
                <h5 className="h5 gray-line" dangerouslySetInnerHTML={{__html: this.state.settings?.homepage_article_carousel_title ?? ''}}></h5>
              </div>
              <h4 className="h3" dangerouslySetInnerHTML={{__html: this.state.settings?.homepage_article_carousel_subtitle ?? ''}}>
              </h4>
            </div>
            <Carousel infinite swipeable={true} draggable={true} responsive={this.responsive} className="no-select square-grid-body">
              { 
                this.state.articles?.map((article: Article, index: number) => {
                  return (
                    <ArticleGridItem 
                    key={article.id}
                    ImageSrc={article.imageURL ?? "assets/pic" + (index % 8 + 1) + ".jpg"}
                    Title={article.title}
                    Slug={article.slug}
                    MediaDescription={article.media_description}
                    />
                  )
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    );
  }
}
