import { Component } from "react";
import { Article } from "../app/models/article";
import axios, { AxiosError, AxiosResponse } from "axios";
import { setTitle, getTitleForStaticPage } from "../utils/titleHelpers";
import ArticleSection from "./article-sections/articleSection";
import { Video } from "../app/models/video";

interface UniqueHotelsPageState {
  articles: Article[] | null;
  featuredVideo: Video | null;
  featuredArticles: Article[] | null;
  readMoreArticles: Article[] | null;
  settings: Record<string, string> | null;
}

export default class UniqueHotelsPage extends Component<any, UniqueHotelsPageState> {
  state: UniqueHotelsPageState = {
    articles: null,
    featuredArticles: null,
    featuredVideo: null,
    readMoreArticles: null,
    settings: null
  };
  
  componentDidMount() {
    this.fetchSettings();

    this.fetchUniqueHotels();
    this.fetchFeaturedVideo();
    this.fetchFeaturedArticles();
    this.fetchReadMoreArticles();
  }

  private fetchSettings() {
    const wnd = (window as any);
    const PAGE_UNIQUE_HOTELS_SETTINGS = wnd.PAGE_UNIQUE_HOTELS_SETTINGS;
    if(PAGE_UNIQUE_HOTELS_SETTINGS && Object.keys(PAGE_UNIQUE_HOTELS_SETTINGS).length) {
      this.setState({ settings: PAGE_UNIQUE_HOTELS_SETTINGS });
      return;
    }

    const V_PAGE_UNIQUE_HOTELS = wnd.V_PAGE_UNIQUE_HOTELS;

    axios
      .get('/cache/page_unique_hotels.json?' + V_PAGE_UNIQUE_HOTELS)
      .then((response: AxiosResponse) => {
        this.setState({ settings: response.data });
      })
      .catch(error => console.log(error));;
  }
  
  private fetchUniqueHotels() {
    axios
      .get('/api/articles/category/unique-hotels', {
        params: {
          status: 1
        }
      })
      .then((response: AxiosResponse) => {
        this.setState({ articles: response.data });

      })
      .catch((error: AxiosError<any>) => {
        console.log(error);
        if ('message' in error.response?.data)
          alert('Failed to fetch the article: ' + error.response?.data.message);
      })
      .then(() => { });
  }

  fetchReadMoreArticles() {
    axios
    .get("/api/articles/readMoreArticles/unique-hotels")
    .then(response => this.setState({readMoreArticles: response.data}))
    
    .catch(error => console.log(error));
    };
    
  fetchFeaturedArticles() {
      const wnd = (window as any);
      const V_FEATURED_ARTICLES_4 = wnd.V_FEATURED_ARTICLES_4;

      axios
      .get("/cache/featured_articles_4.json?" + V_FEATURED_ARTICLES_4)
      .then(response => this.setState({featuredArticles: response.data}))
      
      .catch(error => console.log(error));
    };
    
  fetchFeaturedVideo() {
      const wnd = (window as any);
      const V_FEATURED_VIDEO = wnd.V_FEATURED_VIDEO;

      axios
      .get("/cache/featured_video.json?" + V_FEATURED_VIDEO)
      .then(response => this.setState({featuredVideo: new Video(response.data)}))
      
      .catch(error => console.log(error));
  };
    
  render() {
    setTitle(getTitleForStaticPage());
    return (
      <>
      {this.state.articles !== null &&
      this.state.settings !== null &&
      this.state.featuredVideo !== null &&
      this.state.featuredArticles !== null &&
      this.state.readMoreArticles !== null &&
      <ArticleSection 
      Settings={this.state.settings}
      Articles={this.state.articles}
      FeaturedVideo={this.state.featuredVideo!}
      FeaturedArticles={this.state.featuredArticles!}
      ReadMoreArticles={this.state.readMoreArticles!}
      />
      }
      </>
    );
  }
}
