import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function AppContainer(props: any) {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
  
    var pathName: string = location.pathname.substring(1);
    if(pathName.length === 0)
      pathName = "home";
  
    const elementClass = "App page-" + pathName;
    return (
      <div className={elementClass}>
       {props.children}
      </div>
    );
  };