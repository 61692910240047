import { Component } from "react";
import { withRouter } from "react-router-dom";
import { Video } from "../../app/models/video";
import VideoList from "./videoPageList";
import axios, { AxiosError, AxiosResponse } from "axios";
import { getTitleForStaticPage, setTitle } from "../../utils/titleHelpers";

export class VideosPage extends Component<any, any> {
  state = {
    mainVideo: new Video({}),
    suggestedVideos: Array<Video>(),
    videos: Array<Video>(),
    videoListKey: 0,
    video: ''
  };

  constructor(props: any) {
    super(props);

    this.handleVideoSwitch = this.handleVideoSwitch.bind(this);

    var route = this.props.match.params;

    if('video' in route) {
      this.state.video = route.video;
    }

  }

  async componentDidMount() {
    await this.loadVideos(() =>{
      var loadDefault = true;

      if(this.state.video) {
        loadDefault = false;
  
        var video = this.getVideoByID(this.state.video);
        if(video !== undefined)
          this.setState({mainVideo: video});
        else
          loadDefault = true;
      }
      console.log(this.state.videos);
  
      if(loadDefault && this.state.videos.length){
        this.setState({mainVideo: this.state.videos[0]});
      }
  
      this.loadSuggestedVideos();
    });
   
  }

  private loadSuggestedVideos() {
      this.setState({suggestedVideos: this.getOtherVideos()}, () => {
        this.setState({videoListKey: this.state.videoListKey + 1});
      });
  }

  private getOtherVideos() {
    var activeVideo = this.state.mainVideo;
    return this.state.videos.filter(function (x) { return x !== activeVideo; });
  }

  getVideoByID(video: string): Video | undefined {
    for(var i in this.state.videos){
      var videoID = parseInt(video);

      if((!isNaN(videoID) && this.state.videos[i].id === videoID) || this.state.videos[i].slug === video){
        return this.state.videos[i];
      }
    }
  }

  
  handleVideoSwitch(id: any) {
    var video = this.getVideoByID(id);
    if(video !== undefined) {
      this.setState({ mainVideo: video }, () => { this.loadSuggestedVideos(); });
    }
    else
      alert('Video does not exist');
  }


  componentWillUnmount() {}

  async loadVideos(postCallback: CallableFunction) {
    const wnd = (window as any);
    const V_VIDEOS = wnd.V_VIDEOS;

    await axios.get('/cache/videos.json?' + V_VIDEOS)
    .then((response: AxiosResponse) => {
      this.setState({videos: response.data}, () => postCallback());
    })
    .catch((error: AxiosError<any>) => {
      console.log(error);
      if('message' in error.response?.data)
        alert('Failed to fetch the article: ' + error.response?.data.message);
    });
  }

  render() {
    if(this.state.mainVideo.id === undefined)
      setTitle(getTitleForStaticPage());
    else {
      setTitle(this.state.mainVideo.title ?? null);
    }
    return (
      <div>
        <section className="showcaseVideo">
            { this.state.mainVideo.id !== undefined &&
            <div className="videoContainer">
              <video key={"/uploads/videos/" + this.state.mainVideo.source} width="100%" height="100%" controls autoPlay loop muted >
                <source src={"/uploads/videos/" + this.state.mainVideo.source} type="video/mp4"/>
              </video>
              <div className="overlayText">
                  <p className="topText">{this.state.mainVideo.title}</p>
              </div>
            </div>
            }
            
        </section>
 
        <div style={{ paddingTop: "30px" }}>
          <div className="mx2" style={{ flex: "1" }}>

            <div className="margin--auto">
              <div className="destinations mb--md">
                <div className="destinations__container">
                  <div className="destinations__headline">
                    <div className="destinations__title">
                      <h5 className="h5 gray-line">
                      Suggested videos
                      </h5>
                    </div>
                    <h4 className="h3">
                      One-of-a-kind Experiences in the World's Best Destinations
                    </h4>
                  </div>
                  
                  <div className="destinations__list">
                    {
                      this.state.suggestedVideos !== undefined ? 
                      <VideoList 
                      key={this.state.videoListKey}
                      Videos={this.state.suggestedVideos}
                      SwitchFunction={this.handleVideoSwitch}
                      />
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    );
  }
}

export default withRouter(VideosPage);