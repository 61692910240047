import React, { Component } from "react";

interface LandingVideoSettings {
  homepage_bottom_video?: string;
  homepage_bottom_video_thumbnail?: string;
}

interface LandingVideoState {
  settings?: LandingVideoSettings;
}

export default class LandingVideo extends Component<any, LandingVideoState> {
  state: LandingVideoState = {};

  componentDidMount() {
      this.fetchSettings();
  }
 
  fetchSettings() {
    const wnd = (window as any);
    const HOMEPAGE_BOTTOM_VIDEO_SETTINGS = wnd.HOMEPAGE_BOTTOM_VIDEO_SETTINGS;
    if(HOMEPAGE_BOTTOM_VIDEO_SETTINGS && Object.keys(HOMEPAGE_BOTTOM_VIDEO_SETTINGS).length) {
      this.setState({ settings: HOMEPAGE_BOTTOM_VIDEO_SETTINGS });
      return;
    }
}

  render() {
    return this.state.settings?.homepage_bottom_video && (
      <div className="mx">
        <article className="mb--lg video-module video-module__embed">
          <video width="100%" height="100%" controls playsInline preload="metadata" poster={this.state.settings?.homepage_bottom_video_thumbnail}>
           <source src={this.state.settings?.homepage_bottom_video} type="video/mp4" />
          </video>
        </article>
      </div>
    );
  }
}
