import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class VideoPageListItem extends Component<any, any> {
  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="destinations-card" key={this.props.id}>
        <Link
          to={"/videos/" + this.props.slug ?? this.props.id}
          className="destinations-card__container"
          onClick={() => this.props.SwitchFunction(this.props.slug ?? this.props.id)}
        >
          <div className="destinations-card__media">
            <div className="destinations-card__aspect">
              <figure className=" destinations-card__asset asset-holder--has-captions asset-holder__image" style={{ backgroundImage: "url('/uploads/videos/" + this.props.src + "')" }}>
                <img alt={this.props.description} className="asset__image sr-only" src={"/uploads/videos/" + this.props.src} loading="lazy"
                />
                <figcaption
                  className="asset-holder__image--caption"
                >
                  {this.props.title}
                </figcaption>
              </figure>

              <div className="destinations-card__badge h5">
                2022
              </div>
            </div>
          </div>

          <div className="destination__headline">
            <h3 className="h4 destinations-card__title">
              {this.props.title}
              <i className="icon icon-circle-arrow" style ={{ height:'auto'}}></i>
            </h3>
            <div className="destinations-card__subtitle">
              {this.props.excerpt}
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
