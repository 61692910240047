import { Component } from "react";
import { Destination } from "../../app/models/destination";

export class DropdownItem {
  link: string;
  title: string;
  
  constructor(props: any) {
    this.title = props.title;
    this.link = props.link;
  }
}

export default class DropdownGroup extends Component<any, any> {

  state = {
    expanded: false,
    animating: false,
    title: "",
    items: []
  };

  constructor(props: any) {
    super(props);

    this.state = {
      title: props.Title,
      items: props.List,
      animating: false,
      expanded: false,
    };
  }

  toggleDropdown = (e: any) => {
    this.setState({expanded: !this.state.expanded});
  }

  render() {

    return <>
        <div className="main-nav-mobile-collapsable">
        <button className="main-nav-mobile-link" onClick={this.toggleDropdown}>{this.state.title}<i className={`fa fa-chevron-${this.state.expanded ? "up" : "down"}`} aria-hidden={this.state.expanded ? "false" : "true"} /></button>
        <div className={`main-nav-mobile-collapsable-children ${this.state.expanded && "show"}`}>
          {
            this.state.expanded &&
            this.state.items.map((x: Destination) => {
              return (
                <a href={"/world/" + x.slug + "/"} key={x.slug}>{x.title}</a>
              )
            })
          }
          </div>
      </div>
      </>
  }
}
