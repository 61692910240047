import Moment from 'moment';
import Output from 'editorjs-react-renderer';
import SubscribeModal from "../subscribeModal";
import ArticleComponent from "./articleComponent";
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { FacebookMessengerIcon, FacebookMessengerShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
const VideoRenderer = ({ data, style, classNames, config }:any) => {
  let content = null;
  if (typeof data === 'object' && data.file.url && typeof data.file.url === 'string') content = data.file.url;

  return content ? <video width="100%" controls preload="auto" playsInline>
  <source src={content+"#t=0.001"} type="video/mp4" />
</video> : '';
};

// Pass your custom renderers to Output
const renderers = {
  video: VideoRenderer,
};
export default class ArticleTemplate2 extends ArticleComponent {

  render() {
    const url = window.location.href;
    const shareText = `Check out this great article about ${this.state.article.title} at ${url}`;
    return (
      <div className="BaseWrap-sc-TURhJ BasePageWrapper-bQpzig eTiIvU fzAKrc page page-theme-standard page--article">
        <main
          id="main-content"
          tabIndex={-1}
          className="BaseWrap-sc-TURhJ BasePageMainContent-cLheJR eTiIvU ePLMwy page__main-content"
        >
          <link rel="stylesheet" href="/articlePageAltInline.css?v2" />
          <link rel="stylesheet" href="/articlePage.css?v2" />

          {this.state.showModal && <SubscribeModal closeFunction={this.closeModal} wrapperRef={this.wrapperRef} closeButtonRef={this.closeButtonRef} /> }

<article className="article main-content" lang="en-US">
    <div className="lede-background">
      <header className="SplitScreenContentHeaderWrapper-kZKchS gcHiPU split-screen-content-header article__content-header">
        <div className="grid grid-items-2 grid-full-bleed grid-no-gap SplitScreenContentHeaderMain-jhHdu dLGcvU">
          <div className="BaseWrap-sc-TURhJ SplitScreenContentHeaderTitleBlock-hjSTvo eTiIvU iKYucP grid--item grid-layout__span-6">
            <div data-testid="ContentHeaderRubric">
              <div className="RubricWrapper-cSFBEL eNEXIS rubric SplitScreenContentHeaderRubric-lbEHfI jjtrnJ">
                {this.state.article.tags?.length && this.state.article.tags[0].length > 0 &&
                 this.state.article.tags.slice(0, 3).map((item) => 
                <a className="RubricLink-CPHAg kCNfdG rubric__link" href={"/search?tag=" + item}><span className="RubricName-eZaHyj FsKDn">{item}</span></a>
                )  }
              </div>
            </div>
            <h1 data-testid="ContentHeaderHed" className="BaseWrap-sc-TURhJ BaseText-fFzBQt SplitScreenContentHeaderHed-fxVOKs eTiIvU gxPbbq grAHhf">{this.state.article.title}</h1>
            <span className="SplitScreenContentHeaderDivider-hGIPXM jTQppS" />
            <div data-testid="BylinesWrapper" className="BaseWrap-sc-TURhJ BylinesWrapper-hkFqmg eTiIvU bylines SplitScreenContentHeaderByline-iBREpY cLyYUc split-screen-content-header__byline">
              <p className="BylineWrapper-iiTsTb gxHRFd byline bylines__byline" data-testid="BylineWrapper" itemProp="author" itemType="http://schema.org/Person"><span className="BaseWrap-sc-TURhJ BaseText-fFzBQt BylinePreamble-igNUzc eTiIvU eeZzSi kntvqh byline__preamble">By </span><span itemProp="name" className="BylineNamesWrapper-dbkCxf erRIa-D"><span data-testid="BylineName" className="BylineName-cKXFOb irUMly byline__name"><a className="BaseWrap-sc-TURhJ BaseText-fFzBQt BaseLink-gZQqBA BylineLink-eZnyPI eTiIvU bljJnV jbuspC nZHeQ byline__name-link button" href="/contributor/ella-riley-adams">{this.state.article.author}</a></span></span></p>
            </div>
            <time data-testid="ContentHeaderPublishDate" className="SplitScreenContentHeaderPublishDate-fBepiw kpIpZd">{this.state.article.created_at !== undefined && Moment(this.state.article.created_at).format('MMMM DD, Y')}</time>
            <div className="BaseWrap-sc-TURhJ SocialIconsWrapper-ixWYBx eTiIvU eavmKn social-icons social-icons--standard SplitScreenContentHeaderSocialShare-liqnyT fagxzj">
              <ul className="SocialIconsList-NTOMk kKiupF social-icons__list">
                <li className="SocialIconsListItem-hYmGVl ijYSfG social-icons__list-item social-icons__list-item--facebook social-icons__list-item--standard">
                  <FacebookShareButton
                        url={url}
                        className="external-link SocialIconExternalLink-gugldc itZswp social-icons__link social-icons__link--facebook"
                      >
                    <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                      <svg className="icon icon-facebook" focusable="false" viewBox="0 0 32 32" width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <title>Facebook</title>
                        <path d="M13.621 11.099V13.302H12V15.995H13.621V24H16.951V15.995H19.186C19.186 15.995 19.395 14.704 19.496 13.292H16.964V11.45C16.964 11.175 17.327 10.804 17.686 10.804H19.5V8H17.033C13.539 8 13.621 10.696 13.621 11.099Z" fill="black" />
                      </svg>
                    </div>
                  </FacebookShareButton>
                </li>
                <li className="SocialIconsListItem-hYmGVl ijYSfG social-icons__list-item social-icons__list-item--facebook social-icons__list-item--standard">
                  <FacebookMessengerShareButton
                  appId="415521637830394"
                  url={url}
                  className="external-link SocialIconExternalLink-gugldc itZswp social-icons__link social-icons__link--facebook"
                  >
                    <FacebookMessengerIcon size={32} round  bgStyle={{fill: 'transparent'}}  iconFillColor="black" className="icon icon-messenger" />
                  </FacebookMessengerShareButton>
                </li>
                <li className="SocialIconsListItem-hYmGVl ijYSfG social-icons__list-item social-icons__list-item--twitter social-icons__list-item--standard">
                  <TwitterShareButton
                    url={shareText}
                    className="external-link SocialIconExternalLink-gugldc itZswp social-icons__link social-icons__link--twitter"
                  >
                    <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                      <svg className="icon icon-twitter" focusable="false" viewBox="0 0 32 32" width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <title>Twitter</title>
                        <path d="M13.032 22.003C19.07 22.003 22.372 17.001 22.372 12.663C22.3719 12.5216 22.3686 12.3803 22.362 12.239C23.0045 11.7744 23.5592 11.1991 24 10.54C23.401 10.8056 22.7656 10.9799 22.115 11.057C22.8003 10.6467 23.3132 10.0013 23.558 9.24103C22.9138 9.62343 22.209 9.89297 21.474 10.038C20.9799 9.50946 20.325 9.15892 19.6112 9.04091C18.8973 8.9229 18.1644 9.04403 17.5265 9.38545C16.8886 9.72688 16.3813 10.2695 16.0836 10.9289C15.7858 11.5884 15.7142 12.3277 15.88 13.032C14.5746 12.9664 13.2976 12.6269 12.132 12.0356C10.9663 11.4444 9.93808 10.6145 9.114 9.60003C8.69297 10.3223 8.56366 11.1782 8.7525 11.9926C8.94134 12.8071 9.43407 13.5187 10.13 13.982C9.60866 13.9664 9.0987 13.8258 8.643 13.572V13.614C8.64319 14.3718 8.90547 15.1063 9.38536 15.6928C9.86525 16.2793 10.5332 16.6818 11.276 16.832C10.7924 16.9633 10.2852 16.9825 9.793 16.888C10.0027 17.5404 10.411 18.1109 10.961 18.5197C11.5109 18.9286 12.1749 19.1552 12.86 19.168C11.6971 20.0805 10.2611 20.5754 8.783 20.573C8.518 20.573 8.257 20.558 8 20.528C9.5011 21.4921 11.248 22.0038 13.032 22.002" fill="black" />
                      </svg>
                    </div>
                  </TwitterShareButton>
                </li>
                <li className="SocialIconsListItem-hYmGVl ijYSfG social-icons__list-item social-icons__list-item--email social-icons__list-item--standard d-mobile">
                  <a
                    aria-label="Share via SMS"
                    className="external-link SocialIconExternalLink-gugldc itZswp social-icons__link social-icons__link--email"
                    href={`sms:///?&body=${encodeURIComponent(shareText)}`}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                      
                    <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                      x="0px" y="0px"
                      width="32"
                      height="32"
                      viewBox="0 0 24 24">
                        <path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17L4 17.17V4h16v12zM7 9h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2z"></path>
                      </svg>
                    </div>
                  </a>
                </li>
                <li className="SocialIconsListItem-hYmGVl ijYSfG social-icons__list-item social-icons__list-item--email social-icons__list-item--standard">
                  <a
                    aria-label="Share via Email"
                    className="external-link SocialIconExternalLink-gugldc itZswp social-icons__link social-icons__link--email"
                    href={`mailto:?subject=${encodeURIComponent(this.state.article.title)}&body=${encodeURIComponent(shareText)}`}
                    rel="nofollow noopener noreferrer"
                    target="_blank"
                  >
                    <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                      <svg
                        className="icon icon-email"
                        focusable="false"
                        viewBox="0 0 32 32"
                        width="32"
                        height="32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Email</title>
                        <path
                          d="M6 23h20V9H6v14zm3.631-12H22.37l-6.368 5.661L9.631 11zM24 12.227V21H8v-8.773l8.002 7.109L24 12.227z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </li>
                {/* <li className="SocialIconsListItem-hYmGVl ijYSfG social-icons__list-item social-icons__list-item--pinterest social-icons__list-item--standard">
                  <a aria-label="Share on Pinterest" data-pin-do="nothing" className="external-link SocialIconExternalLink-gugldc itZswp social-icons__link social-icons__link--pinterest" href="https://www.pinterest.com/pin/create/button/?url=&media=" rel="nofollow noopener noreferrer" target="_blank">
                    <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                      <svg className="icon icon-pinterest" focusable="false" viewBox="0 0 32 32" width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <title>Pinterest</title>
                        <path d="M15.169 18.448C14.793 20.093 14.425 21.678 13.623 22.928C13.377 23.311 13.13 23.793 12.71 24C12.09 20.807 13.387 18.12 13.899 15.436C13.246 14.103 13.652 11.846 15.051 11.59C17.077 11.22 16.543 13.664 16.2 14.8C16.01 15.424 15.671 16.021 15.722 16.705C15.835 18.146 17.648 18.24 18.577 17.497C19.909 16.436 20.295 14.385 20.164 12.7C19.967 10.135 17.062 8.85997 14.496 9.88497C13.173 10.413 11.973 11.628 11.799 13.413C11.709 14.353 11.906 15.104 12.276 15.634C12.331 15.715 12.523 15.857 12.552 16.072C12.61 16.506 12.352 16.974 12.116 17.298C10.802 16.92 10.124 15.741 10.016 14.248C9.76596 10.848 12.558 8.26397 15.841 8.02197C19.348 7.76497 22.126 9.78896 22.384 12.74C22.576 14.933 21.797 17.14 20.561 18.329C19.631 19.221 17.656 20.096 16.041 19.242C15.684 19.052 15.524 18.82 15.169 18.448Z" fill="black" />
                      </svg>
                    </div>
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div data-testid="ContentHeaderLeadAsset" className="BaseWrap-sc-TURhJ SplitScreenContentHeaderLedeBlock-gAmDAY eTiIvU grid--item grid-layout__span-6">
            <span className="BaseWrap-sc-TURhJ SpanWrapper-kGGzGm eTiIvU fCMktF responsive-asset SplitScreenContentHeaderLede-dgCDOt jxgqoA">
              <Carousel showArrows={true} showThumbs={false} showIndicators={false} interval={30000} autoPlay={false} emulateTouch className="no-select">
                        {this.state.article.header_carousel?.map((item) => {
                          return <>
                          {
                            (item.type==='image') ? 
                            <div key={item.url} className="carousel-item-custom" style={{backgroundImage: "url(" + item.url.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/1280/$1.$2") + ")"}}>
                             <img
                                alt={item.label}
                                className="sr-only"
                                src={item.url.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/1280/$1.$2")}
                                loading="lazy"
                                />
                              {!!item.label.length && <p className="legend">{item.label}</p>}
                            </div> :
                            <video width="100%" height="100%" controls muted preload="auto" playsInline>
                              <source src={item.url+"#t=0.001"} type="video/mp4" />
                            </video>
                          }
                          </>
                          })}
                        </Carousel>
            </span>
          </div>
        </div>
        <div className="grid grid-margins grid-items-0">
          <figcaption className="BaseWrap-sc-TURhJ CaptionWrapper-brisHk cvqUss fCvYmw caption SplitScreenContentHeaderCaption-gOzjgD fTqeTc grid--item split-screen-content-header__caption"><span className="BaseWrap-sc-TURhJ BaseText-fFzBQt CaptionText-cOFJqa eTiIvU ggfCmJ hTa-dbB caption__text">{this.state.article.subtitle ?? ""}</span></figcaption>
        </div>
        <aside className="PersistentAsideWrapper-OTtoN iDbZCI persistent-aside persistent-aside--align-left" style={{
          position: 'absolute',
          top: '1375px',
          height: Math.max(0, window.document.body.scrollHeight - 1375) + "px",
          }}>
          <div className="StickyBoxWrapper-euhCxU duhIvo sticky-box article__social-share">
            <div className="StickyBoxPrimary-fEgDcn dFVkFO sticky-box__primary">
              <div className="BaseWrap-sc-TURhJ SocialIconsWrapper-ixWYBx eTiIvU eavmKn social-icons social-icons--has-background social-icons--share">
                <ul className="SocialIconsList-NTOMk kKiupF social-icons__list">
                  <li className="SocialIconsListItem-hYmGVl kGgUZg social-icons__list-item social-icons__list-item--facebook social-icons__list-item--has-background">
                    <FacebookShareButton
                          url={url}
                          className="external-link SocialIconExternalLink-gugldc phwuj social-icons__link social-icons__link--facebook"
                        >
                      <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                        <svg className="icon icon-facebook" focusable="false" viewBox="0 0 32 32" width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <title>Facebook</title>
                          <path d="M13.621 11.099V13.302H12V15.995H13.621V24H16.951V15.995H19.186C19.186 15.995 19.395 14.704 19.496 13.292H16.964V11.45C16.964 11.175 17.327 10.804 17.686 10.804H19.5V8H17.033C13.539 8 13.621 10.696 13.621 11.099Z" fill="black" />
                        </svg>
                      </div>
                    </FacebookShareButton>
                  </li>
                  <li className="SocialIconsListItem-hYmGVl kGgUZg social-icons__list-item social-icons__list-item--facebook social-icons__list-item--has-background">
                    <FacebookMessengerShareButton
                        appId="415521637830394"
                        url={url}
                        className="external-link SocialIconExternalLink-gugldc phwuj social-icons__link social-icons__link--facebook"
                      >
                          <FacebookMessengerIcon size={32} round  bgStyle={{fill: 'transparent'}}  iconFillColor="black" className="icon icon-messenger" />
                        </FacebookMessengerShareButton>
                  </li>
                  <li className="SocialIconsListItem-hYmGVl kGgUZg social-icons__list-item social-icons__list-item--twitter social-icons__list-item--has-background">
                    <TwitterShareButton
                          url={shareText}
                          className="external-link SocialIconExternalLink-gugldc phwuj social-icons__link social-icons__link--twitter"
                        >
                      <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                        <svg className="icon icon-twitter" focusable="false" viewBox="0 0 32 32" width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <title>Twitter</title>
                          <path d="M13.032 22.003C19.07 22.003 22.372 17.001 22.372 12.663C22.3719 12.5216 22.3686 12.3803 22.362 12.239C23.0045 11.7744 23.5592 11.1991 24 10.54C23.401 10.8056 22.7656 10.9799 22.115 11.057C22.8003 10.6467 23.3132 10.0013 23.558 9.24103C22.9138 9.62343 22.209 9.89297 21.474 10.038C20.9799 9.50946 20.325 9.15892 19.6112 9.04091C18.8973 8.9229 18.1644 9.04403 17.5265 9.38545C16.8886 9.72688 16.3813 10.2695 16.0836 10.9289C15.7858 11.5884 15.7142 12.3277 15.88 13.032C14.5746 12.9664 13.2976 12.6269 12.132 12.0356C10.9663 11.4444 9.93808 10.6145 9.114 9.60003C8.69297 10.3223 8.56366 11.1782 8.7525 11.9926C8.94134 12.8071 9.43407 13.5187 10.13 13.982C9.60866 13.9664 9.0987 13.8258 8.643 13.572V13.614C8.64319 14.3718 8.90547 15.1063 9.38536 15.6928C9.86525 16.2793 10.5332 16.6818 11.276 16.832C10.7924 16.9633 10.2852 16.9825 9.793 16.888C10.0027 17.5404 10.411 18.1109 10.961 18.5197C11.5109 18.9286 12.1749 19.1552 12.86 19.168C11.6971 20.0805 10.2611 20.5754 8.783 20.573C8.518 20.573 8.257 20.558 8 20.528C9.5011 21.4921 11.248 22.0038 13.032 22.002" fill="black" />
                        </svg>
                      </div>
                    </TwitterShareButton>
                  </li>
                  <li className="SocialIconsListItem-hYmGVl kGgUZg social-icons__list-item social-icons__list-item--email social-icons__list-item--has-background d-mobile">
                    <a
                      aria-label="Share via SMS"
                      className="external-link SocialIconExternalLink-gugldc phwuj social-icons__link social-icons__link--email"
                      href={`sms:///?&body=${encodeURIComponent(shareText)}`}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                        <svg focusable="false" xmlns="http://www.w3.org/2000/svg"
                          x="0px" y="0px"
                          width="32"
                          height="32"
                          viewBox="0 0 24 24">
                            <path fill="none" d="M0 0h24v24H0V0z"></path><path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17L4 17.17V4h16v12zM7 9h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2z"></path>
                        </svg>
                      </div>
                    </a>
                  </li>
                  <li className="SocialIconsListItem-hYmGVl kGgUZg social-icons__list-item social-icons__list-item--email social-icons__list-item--standard">
                    <a
                      aria-label="Share via Email"
                      className="external-link SocialIconExternalLink-gugldc phwuj social-icons__link social-icons__link--email"
                      href={`mailto:?subject=${encodeURIComponent(this.state.article.title)}&body=${encodeURIComponent(shareText)}`}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                        <svg
                          className="icon icon-email"
                          focusable="false"
                          viewBox="0 0 32 32"
                          width="32"
                          height="32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <title>Email</title>
                          <path
                            d="M6 23h20V9H6v14zm3.631-12H22.37l-6.368 5.661L9.631 11zM24 12.227V21H8v-8.773l8.002 7.109L24 12.227z"
                            fillRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                    </a>
                  </li>
                  {/* <li className="SocialIconsListItem-hYmGVl kGgUZg social-icons__list-item social-icons__list-item--pinterest social-icons__list-item--has-background">
                    <a aria-label="Share on Pinterest" data-pin-do="nothing" className="external-link SocialIconExternalLink-gugldc phwuj social-icons__link social-icons__link--pinterest" href="https://www.pinterest.com/pin/create/button/?url=&media=" rel="nofollow noopener noreferrer" target="_blank">
                      <div className="BaseWrap-sc-TURhJ SocialIconContainer-fbhAuK eTiIvU epAnBp social-icons__icon-container">
                        <svg className="icon icon-pinterest" focusable="false" viewBox="0 0 32 32" width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg">
                          <title>Pinterest</title>
                          <path d="M15.169 18.448C14.793 20.093 14.425 21.678 13.623 22.928C13.377 23.311 13.13 23.793 12.71 24C12.09 20.807 13.387 18.12 13.899 15.436C13.246 14.103 13.652 11.846 15.051 11.59C17.077 11.22 16.543 13.664 16.2 14.8C16.01 15.424 15.671 16.021 15.722 16.705C15.835 18.146 17.648 18.24 18.577 17.497C19.909 16.436 20.295 14.385 20.164 12.7C19.967 10.135 17.062 8.85997 14.496 9.88497C13.173 10.413 11.973 11.628 11.799 13.413C11.709 14.353 11.906 15.104 12.276 15.634C12.331 15.715 12.523 15.857 12.552 16.072C12.61 16.506 12.352 16.974 12.116 17.298C10.802 16.92 10.124 15.741 10.016 14.248C9.76596 10.848 12.558 8.26397 15.841 8.02197C19.348 7.76497 22.126 9.78896 22.384 12.74C22.576 14.933 21.797 17.14 20.561 18.329C19.631 19.221 17.656 20.096 16.041 19.242C15.684 19.052 15.524 18.82 15.169 18.448Z" fill="black" />
                        </svg>
                      </div>
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="StickyBoxPlaceholder-jNQMsI laMCLq" />
          </div>
        </aside>
      </header>
    </div>
    <div className="content-background content-padding-top-large" data-attribute-verso-pattern="article-body">
      <div>
        <div className="article__chunks">
          <div className="grid grid-margins grid-items-2 grid-layout--adrail narrow wide-adrail">
            <div className="BaseWrap-sc-TURhJ BodyWrapper-ctnerm bJPc body grid--item body__container article__body grid-layout__content" data-journey-hook="client-content">
              <div className="body__inner-container">
                <Output data={ this.state.article.content } renderers={renderers}/>
              </div>

            </div>
            <div className="grid--item grid-layout__aside">
              <div className="StickyBoxWrapper-euhCxU dCIpTq sticky-box">
                <div className="StickyBoxPrimary-fEgDcn dFVkFO sticky-box__primary">
                  <div className="Container-imGeBO ftlPuP">
                    <figure data-testid="cne-interlude-container-right-rail" className="VideoFigure-hZUgyy dSdqxn">
                      <p className="BaseWrap-sc-TURhJ BaseText-fFzBQt InterludeHeader-bqgsPm eTiIvU eTuCXZ govYwx">WATCH</p>
                      <figure className="CneVideoEmbedFigure-bJIZil hprwiS cne-video-embed">
                        {this.state.featuredVideo?.video_url!==undefined && <video src={this.state.featuredVideo?.video_url} style={{ width: "100%" }} 
                         autoPlay loop muted controls playsInline></video> }
                      </figure>
                      {/* <a rel="noopener noreferrer" target="_blank" className="BaseWrap-sc-TURhJ BaseText-fFzBQt BaseLink-gZQqBA InterludeTitleLink-bfxTQh eTiIvU leYaMU FQMrp csoDwQ" href="#"> */}
                        <p className="BaseWrap-sc-TURhJ BaseText-fFzBQt InterludeRightRailTitle-fiGQEr eTiIvU fHvmUD cWonjf">{this.state.featuredVideo?.title}</p>
                      {/* </a> */}
                    </figure>
                  </div>
                  <div className="AdWrapper-fFweuL dIvDeZ ad ad--rail">
                    <div className="ad__slot ad__slot--rail" data-node-id="f5u6qm" />
                  </div>
                  <div className="ConsumerMarketingUnitThemedWrapper-kkMeXf hBFNZw consumer-marketing-unit consumer-marketing-unit--display-rail" role="presentation" aria-hidden="true">
                    <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--display-rail" />
                    <div className="journey-unit" />
                  </div>
                </div>
                <div className="StickyBoxPlaceholder-jNQMsI laMCLq" />
              </div>
            </div>
          </div>

          
          <div className="grid grid-margins grid-items-2 grid-layout--adrail narrow wide-adrail">
            <div className="BaseWrap-sc-TURhJ BodyWrapper-ctnerm jOLcNp bJPc body grid--item body__container article__body grid-layout__content" data-journey-hook="client-content">
              <div className="body__inner-container">
              
                  <Carousel showArrows={true} showThumbs={false} showIndicators={false} interval={30000} autoPlay={false} emulateTouch className="no-select">
                  {this.state.article.bottom_carousel?.map((item) => {
                    return <div key={item.url}>
                      {
                          (item.type==='image') ? 
                            <picture className="ResponsiveImagePicture-jJiTGL jyYsQg lead-asset__media responsive-image">
                             <img
                                alt={item.label}
                                className="responsive-image__image"
                                src={item.url.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/1280/$1.$2")}
                                loading="lazy"
                                />
                            </picture> : <video width="100%" controls muted preload="auto" playsInline>
                                <source src={item.url+"#t=0.001"} type="video/mp4" />
                                </video>

                        }
                     {!!item.label.length && <p className="legend">{item.label}</p>}
                    </div>
                    })}

                  </Carousel>
                
              </div>
            
            </div>
            <div className="grid--item grid-layout__aside">
              <div className="StickyBoxWrapper-euhCxU duhIvo sticky-box">
                <div className="StickyBoxPrimary-fEgDcn dFVkFO sticky-box__primary">
                  <div className="AdWrapper-fFweuL dIvDeZ ad ad--rail">
                    <div className="ad__slot ad__slot--rail" data-node-id="yy05jb" />
                  </div>
                  <div className="ConsumerMarketingUnitThemedWrapper-kkMeXf hBFNZw consumer-marketing-unit consumer-marketing-unit--display-rail" role="presentation" aria-hidden="true">
                    <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--display-rail" />
                    <div className="journey-unit" />
                  </div>
                  <div className="RecircMostPopularWrapper-laeRwu hArRvK recirc-most-popular-wrapper viewport-monitor-anchor">
                    <div className="BaseWrap-sc-TURhJ RecircMostPopularContiner-hghWoX eTiIvU XlwWs recirc-most-popular-sparrow-tracking hide-aside-ad" data-most-popular-id="mod-most-popular-1">
                      <div className="BaseWrap-sc-TURhJ RecircMostPopularHeading-ihWnVl eTiIvU aXOXf">Most Popular</div>
                      <ul className="RecircMostPopularItems-ciMfej djGFpd">
                  
                        {this.state.featuredArticles?.map((item) => {
                          return <>
                                <li key={item.id}>
                          <div tabIndex={0} className="summary-item summary-item--has-border summary-item--article summary-item--no-icon summary-item--text-align-left summary-item--layout-placement-side-by-side summary-item--layout-position-image-left summary-item--layout-proportions-33-66 summary-item--side-by-side-align-center summary-item--standard BaseWrap-sc-TURhJ RecircMostPopularSummaryItem-bqCFHs eTiIvU flCHJu" role="button">
                            <div className="summary-item__asset-container">
                              <a className="summary-item__image-link summary-item-tracking__image-link" href={"/article/" + item.slug} aria-hidden="true" tabIndex={-1} data-component-type="recirc-river" data-recirc-id="item-image-1" data-recirc-pattern="summary-item">
                                <span className="BaseWrap-sc-TURhJ SpanWrapper-kGGzGm eTiIvU fCMktF responsive-asset summary-item__image">
                                  <div data-test="aspect-ratio-container" className="AspectRatioContainer-dgQA-Dr idrGmk">
                                    <div className="aspect-ratio--overlay-container">
                                      {(item.imageURL.endsWith(".mp4")) ? 
                                      <video width="100%" muted preload="auto" playsInline autoPlay>
                                      <source src={item.imageURL+"#t=0.001"} type="video/mp4" />
                                      </video> :
                                      <picture className="ResponsiveImagePicture-jIKgcS fArnhQ summary-item__image responsive-image">
                                      <img
                                          alt={item.media_description}
                                          className="ResponsiveImageContainer-dlOMGF byslZC responsive-image__image"
                                          src={item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/400/$1.$2")}
                                          loading="lazy"
                                          />
                                      </picture>}
                                    </div>
                                  </div>
                                </span>
                              </a>
                            </div>
                            <div className="summary-item__content">
                              <div className="RubricWrapper-cSFBEL eNEXIS rubric summary-item__rubric"><span className="RubricName-eZaHyj FsKDn"></span></div>
                              <a className="summary-item-tracking__hed-link summary-item__hed-link" href={"/article/" + item.slug} data-component-type="recirc-river" data-recirc-id="item-hed-1" data-recirc-pattern="summary-item">
                                <div data-testid="ClampWrapper" className="BaseWrap-sc-TURhJ ClampWrapper-bsAiGd eTiIvU kgsWL clamp summary-item__hed">
                                  <div className="BaseWrap-sc-TURhJ ClampContent-gtBHJT eTiIvU kOVOxR">
                                    <div data-testid="SummaryItemHed">
                                      <p>{item.title}</p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <div className="summary-item__byline-date-icon">
                                <div className="summary-item__byline">
                                  <div className="summary-item__byline__content">
                                    <div data-testid="BylinesWrapper" className="BaseWrap-sc-TURhJ BylinesWrapper-hkFqmg eTiIvU bylines">
                                      <p className="BylineWrapper-iiTsTb dMtqPs byline bylines__byline" data-testid="BylineWrapper" itemProp="author" itemType="http://schema.org/Person"><span className="BaseWrap-sc-TURhJ BaseText-fFzBQt BylinePreamble-igNUzc eTiIvU eeZzSi kntvqh byline__preamble">By </span><span itemProp="name" className="BylineNamesWrapper-dbkCxf erRIa-D"><span data-testid="BylineName" className="BylineName-cKXFOb irUMly byline__name">{item.author}</span></span></p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                          </>
                          })}
                        <li>
                          <div className="AdWrapper-fFweuL dIvDeZ BaseWrap-sc-TURhJ RecircMostPopularSummaryItem-bqCFHs eTiIvU flCHJu summary-item--has-border ad ad--aside">
                            <div className="ad__slot ad__slot--aside" data-node-id="nja9ik" />
                          </div>
                        </li>
                      </ul>
                      <div className="BaseWrap-sc-TURhJ RecircMostPopularFooter-dUCGtU eTiIvU lImlW" />
                    </div>
                  </div>
                </div>
                <div className="StickyBoxPlaceholder-jNQMsI laMCLq" />
              </div>
            </div>
          </div>
          
        </div>
        <div className="grid grid-margins grid-items-2 grid-layout--adrail narrow wide-adrail">
          <div className="grid--item body body__inline-barrier article__body grid-layout__content">
            <div className="container container--body">
              <div className="container--body-inner">
                <aside className="PaywallInlineBarrierWrapper-bpaAxo lhNbqc" data-testid="PaywallInlineBarrierWrapper">
                  <div className="ConsumerMarketingUnitThemedWrapper-kkMeXf hBFNZw consumer-marketing-unit consumer-marketing-unit--paywall-inline-barrier" role="presentation" aria-hidden="true" aria-live="polite">
                    <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--paywall-inline-barrier" />
                    <div className="journey-unit" />
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
  <div className="BaseWrap-sc-TURhJ ContentFooterWrapper-eNmbwu eTiIvU bjhwHS">
    <div className="BaseWrap-sc-TURhJ RowWrapper-egDQBE eTiIvU hVsKfd">
      <div className="grid grid-margins grid-items-2 grid-layout--adrail narrow wide-adrail" />
    </div>
    <div data-testid="ContentFooterBottom" className="BaseWrap-sc-TURhJ ContentFooterBottom-bZXYQy eTiIvU gOYZr">
      <div className="ContentFooterNewsletterContentWrapper-kZJviu eHIcGR">
        <div className="grid grid-margins grid-items-2 grid-layout--adrail narrow wide-adrail">
          <div data-testid="NewsletterSubscribeFormWrapper" className="BaseWrap-sc-TURhJ NewsletterSubscribeFormWrapper-grcsXl eTiIvU eGinJE newsletter-subscribe-form ContentFooterNewsletterForm-cGMgey iMdTYU grid--item grid-layout__content">
            <h3 className="BaseWrap-sc-TURhJ NewsletterSubscribeFormDangerousHed-loIGYV dYaHUD WBxl newsletter-subscribe-form__hed ContentFooterNewsletterForm-cGMgey iMdTYU grid--item grid-layout__content">Vacation Muse Weekly</h3>
            <div className="BaseWrap-sc-TURhJ NewsletterSubscribeFormDek-jVPNWw eTiIvU keixZI newsletter-subscribe-form__dek">Love to travel? Get FREE email communications from Vacation Muse, covering must-see travel destinations, expert trip planning advice, and travel inspiration to fuel your passion.</div>
            <form className="form-with-validation NewsletterSubscribeFormValidation-drAFnZ irxneZ" id="newsletter" name="newsletter" noValidate method="POST">
              <span className="BaseWrap-sc-TURhJ TextFieldWrapper-fzQmAh XKIjq fjRFiG text-field" data-testid="TextFieldWrapper__email">
                <label className="BaseWrap-sc-TURhJ BaseText-fFzBQt TextFieldLabel-gPTGiA eTiIvU leYaMU hUQHRm text-field__label text-field__label--single-line" htmlFor="newsletter-text-field-email" data-testid="TextFieldLabel__email">
                  <div className="BaseWrap-sc-TURhJ TextFieldLabelText-iYwLHd eTiIvU cPazUh text-field__label-text" />
                  <div className="BaseWrap-sc-TURhJ TextFieldInputContainer-fva-dAv eTiIvU khqwQw"><input type="email" aria-describedby="privacy-text" aria-invalid="false" id="newsletter-text-field-email" required name="email" className="BaseInput-jNjmrm TextFieldControlInput-dlkCbz cfTVp hjcsRA text-field__control text-field__control--input" data-testid="TextFieldInput__email" defaultValue="" /></div>
                </label>
                <button className="BaseButton-aWfgy ButtonWrapper-dOcxiw ikcNWp bvLMvt button button--utility TextFieldButton-hEMtxo kclTsF text-field__button" data-testid="Button" type="submit"><span className="ButtonLabel-eBwykR kgbwGm button__label">Sign Up Now</span></button>
              </span>
              <div id="privacy-text" tabIndex={-1} className="BaseWrap-sc-TURhJ NewsletterSubscribeFormDisclaimer-dgVOgx eTiIvU klXDTo">
                <span>
                  <p>By signing up you agree to our <Link to="/">User Agreement</Link> and <Link to="/">Privacy Policy &amp; Cookie Statement</Link>.</p>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="content-bottom-anchor" />
      <div className="ConsumerMarketingUnitThemedWrapper-kkMeXf hBFNZw consumer-marketing-unit consumer-marketing-unit--article-footer" role="presentation" aria-hidden="true">
        <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--article-footer" />
        <div className="journey-unit" />
      </div>
      <div className="recirc-list-wrapper viewport-monitor-anchor">
        <div className="BaseWrap-sc-TURhJ RowWrapper-egDQBE eTiIvU hVsKfd">
          <div className="grid grid-items-2">
            <aside className="RecircListTextOverlayWrapper-bLObhD WmOiI grid--item ContentFooterRelated-gQYHdu eDhtoX grid-layout__span-6">
              <div className="recirc-list__container">
                <header className="BaseWrap-sc-TURhJ BaseText-fFzBQt RecircListTextOverlayHeading-jhFeyI eTiIvU kNisbL dMvSBq">Read More</header>
                <ul className="RecircListTextOverlayItems-jUBsFG bjQiyU recirc-list__items hide-read-more-ad">
                {this.state.readMoreArticles?.map((item) => {
                          return <>
                  <li className="RecircListTextOverlayItem-bhQSNt iPnToy">
                    <a className="overlay-summary-item summary-item-tracking__image-link summary-item--layout-proportions-overlay" data-component-type="recirc-river" data-recirc-id="item-image-1" data-recirc-pattern="summary-item" href={"/article/" + item.slug} 
                   >
                      <span className="BaseWrap-sc-TURhJ SpanWrapper-kGGzGm eTiIvU fCMktF responsive-asset overlay-summary-item__image">
                        {(item.imageURL.endsWith(".mp4")) ? 
                        <video width="100%" muted preload="auto" playsInline autoPlay>
                        <source src={item.imageURL+"#t=0.001"} type="video/mp4" />
                        </video> :
                        <picture className="ResponsiveImagePicture-jIKgcS fArnhQ overlay-summary-item__image responsive-image">
                        <img
                            alt={item.media_description}
                            className="ResponsiveImageContainer-dlOMGF byslZC responsive-image__image"
                            src={item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/800/$1.$2")}
                            loading="lazy"
                            />
                        </picture>}
                      </span>
                      <div className="overlay-summary-item__content">
                        {item.tags != null && item.tags[0].length > 0 && item.tags.map((tag) =>
                        <div className="RubricWrapper-cSFBEL jLqlIL rubric rubric--with-bg overlay-summary-item__rubric"><span className="RubricName-eZaHyj FsKDn">{tag}</span></div>)}
                        <div data-testid="ClampWrapper" className="BaseWrap-sc-TURhJ ClampWrapper-bsAiGd eTiIvU kgsWL clamp">
                          <div className="BaseWrap-sc-TURhJ ClampContent-gtBHJT eTiIvU kOVOxR">
                            <h3 className="overlay-summary-item__hed">
                              <p>{item.title}</p>
                            </h3>
                          </div>
                        </div>
                        <div data-testid="BylinesWrapper" className="BaseWrap-sc-TURhJ BylinesWrapper-hkFqmg eTiIvU bylines overlay-summary-item__byline">
                          <p className="BylineWrapper-iiTsTb dMtqPs byline bylines__byline" data-testid="BylineWrapper" itemProp="author" itemType="http://schema.org/Person"><span className="BaseWrap-sc-TURhJ BaseText-fFzBQt BylinePreamble-igNUzc eTiIvU eeZzSi kntvqh byline__preamble">By </span><span itemProp="name" className="BylineNamesWrapper-dbkCxf erRIa-D"><span data-testid="BylineName" className="BylineName-cKXFOb irUMly byline__name">{item.author}</span></span></p>
                        </div>
                      </div>
                    </a>
                  </li>
                </>
                })}
                </ul>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <div className="BaseWrap-sc-TURhJ RowWrapper-egDQBE eTiIvU hVsKfd">
        <div className="grid grid-margins grid-items-2 grid-full-bleed">
          <div className="BaseWrap-sc-TURhJ OutbrainWidgetWrapper-fFjWuu eTiIvU enuvAn ContentFooterOutbrainWidget-fElhcJ GIqhI grid--item grid-layout__span-6" data-testid="OutbrainWidgetWrapper">
            <div className="BaseWrap-sc-TURhJ OutbrainWidgetContent-jsIKcw eTiIvU OUTBRAIN" />
          </div>
        </div>
      </div>
      <div className="BaseWrap-sc-TURhJ RowWrapper-egDQBE eTiIvU hVsKfd">
        <div className="AdWrapper-fFweuL dIvDeZ ad ad--footer">
          <div className="ad__slot ad__slot--footer" />
        </div>
      </div>
    </div>
  </div>


        </main>

      </div>
    );
  }
}

                 