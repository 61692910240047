import { Component } from "react";
import "./destinationPage.css";
import { withRouter } from "react-router-dom";
import { Destination } from "../../app/models/destination";
import DestinationPageList from "./destinationPageList";
import axios, { AxiosError, AxiosResponse } from "axios";
import DestinationsMenu from "../destinations-menu";
import { setTitle, getTitleForStaticPage } from "../../utils/titleHelpers";

export class DestinationPage extends Component<any, any> {
  state = {
    destinations: Array<Destination>(),
    continent: null,
    country: null,
    city: null,
    currentDestination: new Destination({}),
    destinationType: ''
  };

  constructor(props: any) {
    super(props);

    var route = this.props.match.params;

    if('continent' in route) {
      this.state.continent = route.continent;
      this.state.destinationType = 'continent';
    }
    if('country' in route) {
      this.state.country = route.country;
      this.state.destinationType = 'country';
    }
    if('city' in route) {
      this.state.city = route.city;
      this.state.destinationType = 'city';
    }

  }
  async getCurrentDestination() {
    var type = this.state.destinationType;
    var slug;
    switch(type) {
      case 'continent':
        slug = this.state.continent;
        break;
      case 'country':
        slug = this.state.country;
        break;
      case 'city':
        slug = this.state.city;
        break;
    }

    if(!slug)
      return null;

    axios.get('/api/destinations/' + slug)
    .then((response: AxiosResponse) => {
      this.setState({currentDestination: response.data}, () => {
        setTitle(this.state.currentDestination.title);
      });
    })
    .catch((error: AxiosError<any>) => {
      console.log(error);
      if('message' in error.response?.data)
        alert('Failed to fetch the destination: ' + error.response?.data.message);
    })
    .then(() => { });
 
  }

  async componentDidMount() {
    await this.getCurrentDestination();
  }

  render() {
    if(!this.state.destinationType) {
      setTitle(getTitleForStaticPage());
    }
    
    return (
      <div>
        {(this.state.currentDestination.children?.length ?? 0) > 0 &&
    <div className="lede-background">
      <header className="SplitScreenContentHeaderWrapper-kZKchS gcHiPU split-screen-content-header article__content-header">
        <div className="grid grid-items-2 grid-full-bleed grid-no-gap SplitScreenContentHeaderMain-jhHdu dLGcvU">
          <div className="BaseWrap-sc-TURhJ SplitScreenContentHeaderTitleBlock-hjSTvo eTiIvU iKYucP grid--item grid-layout__span-6">
          {this.state.currentDestination.id !== undefined &&
            this.state.currentDestination.children?.length &&
            <DestinationsMenu
              destination={this.state.currentDestination}
              destinationType={this.state.destinationType}
            />}
           
     
          </div>
          <div data-testid="ContentHeaderLeadAsset" className="BaseWrap-sc-TURhJ SplitScreenContentHeaderLedeBlock-gAmDAY eTiIvU grid--item grid-layout__span-6">
            <span className="BaseWrap-sc-TURhJ SpanWrapper-kGGzGm eTiIvU fCMktF responsive-asset SplitScreenContentHeaderLede-dgCDOt jxgqoA">
              {/* <div className="carousel-item-custom" style={{backgroundImage: "url(" + item.url + ")"}}>
                {!!item.label.length && <p className="legend">{item.label}</p>}
              </div> */}
                <section className="showcaseDestination-new">
                {
                  this.state.currentDestination.imageURL?.endsWith(".mp4") ?  <video loop autoPlay muted playsInline>
                  <source src={this.state.currentDestination.imageURL } type="video/mp4" />
                  </video> :<div style={{
                    backgroundImage: "url(" + (this.state.currentDestination.imageURL !== null ? this.state.currentDestination.imageURL : "https://greenglobaltravel.com/wp-content/uploads/2017/04/Best-National-Parks-In-USA-For-Wildlife-Watching.jpg") + ")",
                    width: "100%",
                    height: "100%",
                    backgroundRepeat: "none",
                    backgroundPosition: "center",
                    backgroundSize: "cover"
                  }}></div>
                }
                <div className="text" style={{position: "absolute"}}>
                  <h3>Explore {this.state.currentDestination.fullTitle || this.state.currentDestination.title}</h3>
                </div>
              </section>
            </span>
          </div>
        </div>
      </header>
    </div>
  }

       
          {(this.state.currentDestination.children?.length ?? 0) > 0 &&
           <div className="destinations__title" style={{ paddingTop: (this.state.currentDestination.children?.length ?? 0) > 0 ? "30px" : "60px", paddingLeft: "30px", paddingRight: "30px" }}>
            <h5 className="h5 gray-line">
            Explore destinations inside {this.state.currentDestination.fullTitle || this.state.currentDestination.title}
            </h5>
            </div>
          }
          
        <div>
          <div className="mx2" >
          {
            this.state.currentDestination.articles !== undefined &&
            this.state.destinationType === 'city' &&

            <div className="dpage-right" >
              <h4 className="font-semibold text-sm uppercase">
                <b>Type</b>
              </h4>
              <div >
                <div data-testid="visibleFilters" className="article-filters">
                  <span className="text-sm w-1-2 mt-8">
                    <label style = {{paddingRight:"20px", display:"inline-block" }}>
                      <input
                        type="checkbox"
                        name="Museum"
                        className="h-16 w-16 mr-8 bg-white border-0 cursor-pointer flex-shrink-0"
                        style = {{verticalAlign:"middle"}}
                      />
                      <span style = {{verticalAlign:"middle"}}>Museum</span>
                    </label>
                  </span>
                  <span className="text-sm w-1-2 mt-8">
                  <label style = {{paddingRight:"20px", display:"inline-block"}}>
                      <input
                        type="checkbox"
                        name="National Park"
                        className="h-16 w-16 mr-8 bg-white border-0 cursor-pointer flex-shrink-0"
                        style = {{verticalAlign:"middle"}}
                      />
                      <span style = {{verticalAlign:"middle"}}>National Park</span>
                    </label>
                  </span>
                  <span className=" text-sm w-1-2 mt-8">
                  <label style = {{paddingRight:"20px", display:"inline-block"}}>
                      <input
                        type="checkbox"
                        name="Beach"
                        className="h-16 w-16 mr-8 bg-white border-0 cursor-pointer flex-shrink-0"
                        style = {{verticalAlign:"middle"}}
                      />
                      <span style = {{verticalAlign:"middle"}}>Beach</span>
                    </label>
                  </span>
                  <span className=" text-sm w-1-2 mt-8">
                  <label style = {{paddingRight:"20px", display:"inline-block"}}>
                      <input
                        type="checkbox"
                        name="Historic Building"
                        className="h-16 w-16 mr-8 bg-white border-0 cursor-pointer flex-shrink-0"
                        style = {{verticalAlign:"middle"}}
                      />
                      <span style = {{verticalAlign:"middle"}}>Historic Building</span>
                    </label>
                  </span>
                 
                  <span className="text-sm w-1-2 mt-8">
                  <label style = {{paddingRight:"20px", display:"inline-block"}}>
                      <input
                        type="checkbox"
                        name="Wildlife Reserve"
                        className="h-16 w-16 mr-8 bg-white border-0 cursor-pointer flex-shrink-0"
                        style = {{verticalAlign:"middle"}}
                      />
                      <span style = {{verticalAlign:"middle"}}>Wildlife Reserve</span>
                    </label>
                  </span>
                  <span className="text-sm w-1-2 mt-8">
                  <label style = {{paddingRight:"20px", display:"inline-block"}}>
                      <input
                        type="checkbox"
                        name="Historic Site"
                        className="h-16 w-16 mr-8 bg-white border-0 cursor-pointer flex-shrink-0"
                        style = {{verticalAlign:"middle"}}
                      />
                      <span style = {{verticalAlign:"middle"}}>Historic Site</span>
                    </label>
                  </span>
                  <span className="text-sm w-1-2 mt-8">
                  <label style = {{paddingRight:"20px", display:"inline-block"}}>
                      <input
                        type="checkbox"
                        name="Monument"
                        className="h-16 w-16 mr-8 bg-white border-0 cursor-pointer flex-shrink-0"
                        style = {{verticalAlign:"middle"}}
                      />
                      <span style = {{verticalAlign:"middle"}}>Monument</span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
            }
                <div className="destinations__container">
                  <div className="destinations__headline"></div>
                  <div className="destinations__list">
                    {
                      this.state.currentDestination.children !== undefined &&
                      <DestinationPageList 
                      Title={this.state.currentDestination.title}
                      Description={this.state.currentDestination.description}
                      Destinations={this.state.currentDestination.children}
                      DisplayChildren={true}
                      Horizontal={this.state.destinationType !== 'city'}
                      />
                    }
                    
                    {this.state.currentDestination.id !== undefined &&
                      <div className="destinations__title" style={{ paddingTop: (this.state.currentDestination.children?.length ?? 0) > 0 ? "30px" : "60px", paddingLeft: "30px", paddingRight: "30px" }}>
                      <h5 className="h5 gray-line">
                      {(this.state.currentDestination.articles?.length ?? 0) > 0 ?
                      <span>Explore articles for {this.state.currentDestination.fullTitle || this.state.currentDestination.title}</span>
                      : ""}
                      {(!(this.state.currentDestination.children?.length ?? 0) && !(this.state.currentDestination.articles?.length ?? 0)) ?
                      <span>No articles available for {this.state.currentDestination.fullTitle || this.state.currentDestination.title}<br/>
                      <a href="javascript:history.back()" style={{color: "#000"}}>Go back</a></span>
                      : ""}
                      </h5>
                      <div className="destinations__headline"></div>
                    </div>
                    }

                    {
                      this.state.currentDestination.articles !== undefined &&
                      <DestinationPageList 
                      Title={this.state.currentDestination.title}
                      Description={this.state.currentDestination.description}
                      Articles={this.state.currentDestination.articles}
                      DisplayChildren={false}
                      Horizontal={true}
                      />
                    }

                  </div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default withRouter(DestinationPage);