import React, { Component } from "react";

export default class SearchResultItem extends Component<any, any> {
  render() {
    return (
      <>
        <div className={`title-card river-full-width__item ${this.props.isEven ? "river-full-width__item--even" : ""}`}>
          <div className="title-card__container">
            <a className="title-card__image-link" href={this.props.articleLink} tabIndex={0}>
              <div className="styled-responsive-image title-card__image styled-responsive-image-with-transitions styled-responsive-image-with-transitions--show" style={{backgroundColor: 'rgb(229, 229, 229)'}}>
                <style dangerouslySetInnerHTML={{__html: "\n                        @media (min-width: 0px) {\n                        .xs-91bd7747 {\n                        padding-top: 66.666666666%;\n                        }\n                        }\n                        @media (min-width: 640px) {\n                        .sm-91bd7747 {\n                        padding-top: 66.666666666%;\n                        }\n                        }\n                        @media (min-width: 768px) {\n                        .md-91bd7747 {\n                        padding-top: 100%;\n                        }\n                        }\n                        @media (min-width: 1024px) {\n                        .lg-91bd7747 {\n                        padding-top: 66.666666666%;\n                        }\n                        }\n                        @media (min-width: 1280px) {\n                        .xl-91bd7747 {\n                        padding-top: 66.666666666%;\n                        }\n                        }\n                        @media (min-width: 1600px) {\n                        .xxl-91bd7747 {\n                        padding-top: 66.666666666%;\n                        }\n                        }\n                     " }} />
                <div className="styled-responsive-image__placeholder xs-91bd7747 sm-91bd7747 md-91bd7747 lg-91bd7747 xl-91bd7747 xxl-91bd7747" />
                {this.props.imageURL.endsWith('.mp4') ?  <video className="styled-responsive-image__img" width="100%"  autoPlay loop muted playsInline>
                                <source src={this.props.imageURL} type="video/mp4" />
                                </video> :
                                 <picture className="styled-responsive-image__picture">
                  <source srcSet={this.props.imageURL} />
                  <img className="styled-responsive-image__img" alt="" loading="lazy" />
                </picture>}
              </div>
              <div className="title-card__image-rubric"><span>{this.props.rubric}</span></div>
            </a>
            <div className="title-card__details">
              <div className="title-card__details-rubric"><span>{this.props.rubric}</span></div>
              <a className="title-card__hed-link" href={this.props.articleLink} tabIndex={0}>
                <h2 className="title-card__hed">{this.props.title}</h2>
              </a>
              <p className="title-card__dek">{this.props.description}</p>
              <div className="content-type-details title-card__content-type-details">
                <span className="content-type-details__icon gq-icon gq-icon-article" />
                <span className="content-type-details__label content-type-details__author">
                  {/* react-text: 115 */}By {/* /react-text */}{/* react-text: 116 */}{this.props.author}{/* /react-text */}
                </span>
                <span className="content-type-details__label content-type-details__pub-date">{this.props.date}</span><a className="content-type-details__link" href={this.props.articleLink}><span className="content-type-details__label content-type-details__view-more">View More</span><span className="content-type-details__icon gq-icon gq-icon-arrow-right" /></a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}