import './articleSection.css';
import { Article } from "../../app/models/article";
import React, { Fragment, createRef } from "react";
import { Video } from '../../app/models/video';

interface ArticleSectionProps {
    Settings?: Record<string, string>;
    Articles: Article[];
    FeaturedVideo: Video;
    FeaturedArticles: Article[];
    ReadMoreArticles: Article[];
}

interface ArticleSectionState {
    settings?: Record<string, string>;
    articles: Array<Article>;
    featuredVideo: Video;
    featuredArticles: Array<Article>;
    readMoreArticles: Array<Article>;
    showModal: boolean;
  }

export default class ArticleSection extends React.Component<ArticleSectionProps, ArticleSectionState> {

    state: ArticleSectionState = {
        articles: new Array<Article>(),
        featuredVideo: new Video(null),
        featuredArticles: new Array<Article>(),
        readMoreArticles: new Array<Article>(),
        showModal: false,
        settings: {},
      };
      wrapperRef: React.RefObject<HTMLDivElement>;
      closeButtonRef: React.RefObject<HTMLButtonElement>;
      modalTimeout?: NodeJS.Timeout;
    
      constructor(props: ArticleSectionProps) {
        super(props);
        this.handleModal = this.handleModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.wrapperRef = createRef();
        this.closeButtonRef = createRef();
    
        this.state.settings = props.Settings;
        this.state.articles = props.Articles;
        this.state.featuredVideo=props.FeaturedVideo;
        this.state.featuredArticles=props.FeaturedArticles;
        this.state.readMoreArticles=props.ReadMoreArticles;
      }
    
      componentDidMount() {
        this.modalTimeout = setTimeout(() => {
          this.handleModal()
        }, 10000);
      }
    
      componentWillUnmount(): void {
        this.modalTimeout && clearInterval(this.modalTimeout);
      }
    
      handleModal() {
        this.setState({ showModal: true });
      }
    
      closeModal(event: any) {
        if (
          (this.wrapperRef.current &&
          !this.wrapperRef.current.contains(event.target)) ||
          (this.closeButtonRef.current &&
            this.closeButtonRef.current === event.target)
        ) {
          this.setState({ showModal: false });
        }
    
      }

  render() {
    const baseUrl = window.location.origin;
    return (
      <main
      id="main-content"
      tabIndex={-1}
      className="BasePageMainContent-kFvAuh hHEPkK page__main-content"
    >
    <article className="gallery main-content" lang="en-GB">
        <div
        data-testid="RowWrapper"
        className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan"
        >
        <header
            className="ContentHeaderWrapper-clujbs cYTwve content-header"
        >
            <div
            data-testid="ContentHeaderContainer"
            className="ContentHeaderContainer-cGKRbE hCCDaZ"
            >
            <div className="ContentHeaderHedAccreditationWrapper-GFiQf cDNVlV">
                <div
                data-testid="ContentHeaderTitleBlockWrapper"
                className="ContentHeaderTitleBlockWrapper-cjmSKp hbWJLs"
                >
                <div
                    data-testid="ContentHeaderRubric"
                    className="ContentHeaderRubricBlock-LmpbT bHSyYn"
                >
                    <div
                    data-testid="ContentHeaderRubricDateBlock"
                    className="ContentHeaderRubricDateBlock-kAQcZP fTFWxD"
                    >
                    <div className="RubricWrapper-dKmCNX dFwgPI rubric ContentHeaderRubricContainer-eTudtt iRsSlw">
                        <a
                        className="RubricLink-gRWSOU hWQSFt rubric__link"
                        href="/unique-hotels"
                        >
                        <span className="RubricName-fVtemz cLxcNi rubric__name">
                            {this.state.settings?.unique_hotels_header_small_title}
                        </span>
                        </a>
                    </div>
                    </div>
                </div>
                <h1
                    data-testid="ContentHeaderHed"
                    className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ ContentHeaderHed-NCyCC iUEiRd lnnhgD gydgrM"
                >
                    {this.state.settings?.unique_hotels_header_main_title}
                </h1>
                </div>
                <div
                className="ContentHeaderAccreditation-lfctVB etVsnJ content-header__accreditation"
                data-testid="ContentHeaderAccreditation"
                >
                <div className="ContentHeaderDek-bIqFFZ eLBlA-D">
                    {this.state.settings?.unique_hotels_header_sub_title}
                </div>
                <div className="ContentHeaderByline-kmPyCa emiglw">
                    <div className="ContentHeaderBylineContent-dpPmNn DRFq">
                    <div
                        data-testid="BylinesWrapper"
                        className="BylinesWrapper-KIudk irTIfE bylines ContentHeaderBylines-cZqgyJ bHLeKI"
                    >
                        <p
                        className="BylineWrapper-jWHrLH ixIgep byline bylines__byline"
                        data-testid="BylineWrapper"
                        itemProp="author"
                        itemType="http://schema.org/Person"
                        >
                        <span
                            itemProp="name"
                            className="BylineNamesWrapper-jbHncj fuDQVo"
                        >
                            <span
                            data-testid="BylineName"
                            className="BylineName-kwmrLn gsLuGA byline__name"
                            >
                            <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BylinePreamble-iJolpQ iUEiRd jyeccG dttFsx byline__preamble">
                                By{" "}
                            </span>
                            <a
                                className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BaseLink-eNWuiM BylineLink-gEnFiw iUEiRd hiiKEl dKMOIT bOwDHT byline__name-link button"
                                href="/"
                            >
                                Vacation Muse
                            </a>
                            </span>
                        </span>
                        </p>
                    </div>
                    <time
                        data-testid="ContentHeaderPublishDate"
                        dateTime="2024-05-03T07:00:00Z"
                        className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ ContentHeaderPublishDate-eIBicG iUEiRd bEbFWV PVAIn"
                    >
                        03 May 2024
                    </time>
                    </div>
                </div>
                <div
                    className="SocialIconsWrapper-hKMEXV ffgPVr social-icons social-icons--standard ContentHeaderAccreditationSocialIcons-jorOau fPzfaa content-header__social-share"
                >
                    <ul
                    data-testid="socialIconslist"
                    className="SocialIconsList-cHVTIA ivmblD social-icons__list"
                    />
                </div>
                </div>
            </div>
            <div
                className="ContentHeaderLeadAsset-hGbumP dDDxdb lead-asset ContentHeaderLeadAssetWrapper-hfXHEc frGrck"
                data-testid="ContentHeaderLeadAsset"
            >
                <figure className="ContentHeaderLeadAssetContent-kOfYSG dRGWbI">
                <div className="ContentHeaderLeadAssetContentMedia-bLEIpi jjhWdS lead-asset__content__photo">
                    <span className="SpanWrapper-umhxW jvZaPI responsive-asset ContentHeaderResponsiveAsset-bREgIb hONbnm">
                    <div
                        data-test="aspect-ratio-container"
                        className="AspectRatioContainer-bJHpJz ccdiSo"
                    >
                        <div className="aspect-ratio--overlay-container">
                        <picture className="ResponsiveImagePicture-cWuUZO dUOtEa ContentHeaderResponsiveAsset-bREgIb hONbnm responsive-image">
                            <source
                            media="(max-width: 767px)"
                            srcSet=""
                            sizes="100vw"
                            />
                            <source
                            media="(min-width: 768px)"
                            srcSet=""
                            sizes="100vw"
                            />
                            <img
                            alt=""
                            className="ResponsiveImageContainer-eybHBd fptoWY responsive-image__image"
                            src={this.state.settings?.unique_hotels_header_image}
                            />
                        </picture>
                        </div>
                    </div>
                    </span>
                    <div
                    className="SocialIconsWrapper-hKMEXV ebzboM social-icons social-icons--overlay"
                    >
                    <ul
                        data-testid="socialIconslist"
                        className="SocialIconsList-cHVTIA euDGtn social-icons__list"
                    />
                    </div>
                    <div
                    className="CaptionWrapper-jSZdqE kJnXuV caption ContentHeaderLeadAssetCaption-hPWmSN ihFxch"
                    >
                    <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ CaptionCredit-ejegDm iUEiRd ieWWVq fNaHcW caption__credit">
                        {this.state.settings?.unique_hotels_header_image_description}
                    </span>
                    </div>
                </div>
                </figure>
            </div>
            </div>
            <aside
            className="PersistentAsideWrapper-deVGrR oEIBz persistent-aside persistent-aside--align-left"
            style={{
                position: "absolute",
                top: "1481.75px",
                height: "13789.7px"
            }}
            data-testid="PersistentAsideWrapper"
            >
            <div className="StickyBoxWrapper-jfYBuk jxBcTH sticky-box ContentHeaderPersistentAside-fsnAmQ dFzKzm">
                <div className="StickyBoxPrimary-dzWDWL cdhYoN sticky-box__primary">
                <div
                    className="SocialIconsWrapper-hKMEXV ffgPVr social-icons social-icons--has-background ContentHeaderSocialIcons-jzJiyg fgMkJI social-icons--share"
                >
                    <ul
                    data-testid="socialIconslist"
                    className="SocialIconsList-cHVTIA ivmblD social-icons__list"
                    />
                </div>
                </div>
                <div className="StickyBoxPlaceholder-grPmrg dxAvXx" />
            </div>
            </aside>
        </header>
        </div>
        <div
        data-attribute-verso-pattern="gallery-body"
        className="GalleryPageContentChunks-kNcWor llIxOy"
        >
        <div data-page-value={1}>
            <div
            data-testid="RowWrapper"
            className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan GalleryPageChunkRow-eNGlGj jaMwlG"
            >
            <div className="GridWrapper-cAzTTK cVbjEv grid grid-margins grid-items-2 grid-layout--adrail narrow">
                <div className="GridItem-buujkM stRKV grid--item grid-layout__content">
                <div className="sticky-box__gallery-anchor-top">
                    <div>
                    <div className="GalleryPageTextBlock-lkymFq gMkDtX">
                        <div
                        className="BodyWrapper-kufPGa kNceyU body GalleryPageIntroBody-jRNmRA lHJMC body__container"
                        data-journey-hook="client-content"
                        data-testid="BodyWrapper"
                        >
                        <div>
                            <p>
                            {this.state.settings?.unique_hotels_header_content_intro_paragraph}
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                    <ul className="GalleryPageSlides-hIivta hlAVzT">

                    {this.state.articles.length > 0 ? this.state.articles?.slice(0, 4).map((item) => {
                    return <li
                        key={item.id}
                        className="gallery__slides__slide"
                    >
                        <div>
                        <div
                            className="GallerySlideWrapper-yxPsh iVjEqA product-embed slide-venue slide-hotel impressionTrackingFired"
                            aria-orientation="vertical"
                        >
                            <figure className="GallerySlideFigure-jhDfjI bGQGmP">
                            <div
                                aria-orientation="vertical"
                                className="GallerySlideAssetWrapper-jGbftJ eCdWNA"
                            >
                                <div className="GallerySlideAssetContainer-hwdsAZ gNfA-dy">
                                <div className="GallerySlideAssetContainerInner-BTnef fyLCFI">
                                    <span className="SpanWrapper-umhxW jvZaPI responsive-asset GallerySlideResponsiveAsset-bXKMLC lluiXZ GallerySlideResponsiveAsset slide-venue slide-hotel">
                                    <picture className="ResponsiveImagePicture-cWuUZO dUOtEa GallerySlideResponsiveAsset-bXKMLC lluiXZ GallerySlideResponsiveAsset slide-venue slide-hotel responsive-image">
                                        <source
                                        media="(max-width: 767px)"
                                        srcSet={
                                            item.imageURL.replace(/uploads\//, "uploads/400/") +
                                            " 400w, " +
                                            item.imageURL.replace(/uploads\//, "uploads/800/") +
                                            " 800w"
                                          }
                                        sizes="100vw"
                                        />
                                        <source
                                        media="(min-width: 768px)"
                                        srcSet={item.imageURL.replace(/uploads\//, "uploads/1280/") + " 1280w"}
                                        sizes="100vw"
                                        />
                                        <img
                                        alt={item.media_description}
                                        className="ResponsiveImageContainer-eybHBd fptoWY responsive-image__image"
                                        src={item.imageURL.replace(/uploads\//, "uploads/1280/")}
                                        />
                                    </picture>
                                    </span>
                                    <div
                                    className="SocialIconsWrapper-hKMEXV ebzboM social-icons social-icons--overlay GallerySlideSocialIcons-kAFGFd bnSKCQ"
                                    >
                                    <ul
                                        data-testid="socialIconslist"
                                        className="SocialIconsList-cHVTIA euDGtn social-icons__list"
                                    >
                                        <li className="SocialIconsListItem-dembhR gQILrJ social-icons__list-item social-icons__list-item--pinterest social-icons__list-item--has-background social-icons__list-item--overlay">
                                        <a
                                            aria-label="Post to Pinterest"
                                            className="external-link SocialIconExternalLink-huuzOU hnpTAQ social-icons__link social-icons__link--pinterest"
                                            href={"https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(baseUrl + "/article/" + item.slug) + "&media=" + encodeURIComponent(item.imageURL.replace(/uploads\//, "uploads/1280/")) + "&description="}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <div className="SocialIconContainer-huXzUC pMzVC social-icons__icon-container">
                                            <svg
                                                className="SocialIconNetworkIconComponent-cZaKGz dlOAhZ icon icon-pinterest"
                                                focusable="false"
                                                viewBox="0 0 32 32"
                                                width={32}
                                                height={32}
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <title>Pinterest</title>
                                                <path
                                                d="M15.169 18.448C14.793 20.093 14.425 21.678 13.623 22.928C13.377 23.311 13.13 23.793 12.71 24C12.09 20.807 13.387 18.12 13.899 15.436C13.246 14.103 13.652 11.846 15.051 11.59C17.077 11.22 16.543 13.664 16.2 14.8C16.01 15.424 15.671 16.021 15.722 16.705C15.835 18.146 17.648 18.24 18.577 17.497C19.909 16.436 20.295 14.385 20.164 12.7C19.967 10.135 17.062 8.85997 14.496 9.88497C13.173 10.413 11.973 11.628 11.799 13.413C11.709 14.353 11.906 15.104 12.276 15.634C12.331 15.715 12.523 15.857 12.552 16.072C12.61 16.506 12.352 16.974 12.116 17.298C10.802 16.92 10.124 15.741 10.016 14.248C9.76596 10.848 12.558 8.26397 15.841 8.02197C19.348 7.76497 22.126 9.78896 22.384 12.74C22.576 14.933 21.797 17.14 20.561 18.329C19.631 19.221 17.656 20.096 16.041 19.242C15.684 19.052 15.524 18.82 15.169 18.448Z"
                                                fill="black"
                                                />
                                            </svg>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <figcaption className="GallerySlideFigCaptionWrapper-QgqEb jqOVHf GallerySlideCaptionWrapper-cVIrXn gmZcZa slide-venue slide-hotel">
                                <div className="GallerySlideFigCaption-dOeyTg jgkmHh">
                                <div className="GallerySlideCaptionCreditWrapper-houOem lflAou">
                                    {item.media_description}
                                </div>
                                {item.tags?.length && item.tags[0].length > 0 && 
                                <div className="GallerySlideCaptionPreHeaderTwo-juPgiw iBhGZi">
                                    {item.tags.slice(0, 3).map((tag, index, array) => 
                                     <Fragment key={`tag_${item.id}_${index}`}>
                                     <a
                                     href={`/search/?tag=${tag}`}
                                     className="BaseWrap-sc-TwdDQ BaseText-fFHxRE BaseLink-gZIuAp TagCloudLink-ijMAUj hlNbBe doCFOR bWyKqi kfkJOD"
                                   >
                                     <span className="BaseWrap-sc-TwdDQ BaseText-fFHxRE TagCloudName-hILIUU hlNbBe ffobMw jtzwuU">
                                       {tag}
                                     </span>
                                     
                                   </a>
                                   {array.length -1 === index ? "" : <>,&nbsp;</>}
                                   </Fragment>)}
                                </div>
                                }
                                <a
                                    href={"/article/" + item.slug}
                                >
                                    <h2 className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ GallerySlideCaptionHed-fiZQOl iUEiRd lcmQdU bPoAxq">
                                    <span className="GallerySlideCaptionHedText-iqjOmM jwPuvZ">
                                        {item.title}
                                    </span>
                                    <svg
                                        className="icon icon-arrow"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        width={24}
                                        height={24}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>Arrow</title>
                                        <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="m22.06 12-9.53-9.53-1.06 1.06 7.718 7.719H2v1.5h17.19l-7.72 7.72 1.06 1.061L22.06 12Z"
                                        />
                                    </svg>
                                    </h2>
                                </a>
                                <div
                                    data-testid="GallerySlideCaptionDekContainer"
                                    className="GallerySlideCaptionDekContainer-hLUdt gSWuis"
                                >
                                    <div className="GallerySlideCaptionDek-cXnbPe cGixxE">
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: item.content }}>
                                        </p>
                                        <em>{item.author}</em>
                                    </div>
                                    </div>

                                    {/* <div className="GallerySlideCaptionOffers-fsTPbf gmClBA">
                                    <div
                                        className="ProductOfferListWrapper-bqpHEj cvoxld product-offer-list"
                                    >
                                        <div className="ProductOfferWrapper-dpcYKd hBwhMM product-offer-list__offer product-offer">
                                        <div
                                            className="ProductOfferCtaBlock-bduvOV fbJiRZ product-offer__cta-block"
                                            id="button_label_MakeareservationatExpedia"
                                        >
                                            <a
                                            href="https://cna.st/h/meh9fHuiYWLjqEhp45qVrEFhRzvjysLwSgqMhgUi8NJU8fJSP1jswGXnBECiC6pbZRLVBMKgRDMjf68K5bG3mZRaJ93qub1gwjFoEpDXTjo3fuHTkJJZRmVof5KKsV83CSPK3zVq4am4stKAnuzHXPAQbbBpyootL8WvvGsJ6"
                                            rel="sponsored noopener noreferrer"
                                            target="_blank"
                                            aria-label="Make a reservation at Expedia"
                                            data-buy-button="true"
                                            data-offer-retailer="Expedia"
                                            data-offer-url="https://www.expedia.co.uk/London-Hotels-Claridges.h15588.Hotel-Information"
                                            className="BaseButton-bLlsy ButtonWrapper-xCepQ ljFcOl fRqrDa button button--utility-pair product-offer__buy-button"
                                            data-event-click='{"element":"Button","outgoingURL":"https://cna.st/h/meh9fHuiYWLjqEhp45qVrEFhRzvjysLwSgqMhgUi8NJU8fJSP1jswGXnBECiC6pbZRLVBMKgRDMjf68K5bG3mZRaJ93qub1gwjFoEpDXTjo3fuHTkJJZRmVof5KKsV83CSPK3zVq4am4stKAnuzHXPAQbbBpyootL8WvvGsJ6"}'
                                            data-testid="Button"
                                            type=""
                                            >
                                            <span className="ButtonLabel-cjAuJN hzwRuG button__label">
                                                Make a reservation at Expedia
                                            </span>
                                            </a>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="GallerySlideCaptionCtaBlock-ktoFJX fgjbmf" />
                                    </div> */}
                                </div>
                                </div>
                            </figcaption>
                            </figure>
                        </div>
                        <div className="gallery-slide__cm-container">
                            <div
                            className="ConsumerMarketingUnitThemedWrapper-iUTMTf jssHut consumer-marketing-unit consumer-marketing-unit--gallery-slide"
                            role="presentation"
                            aria-hidden="true"
                            >
                            <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--gallery-slide consumer-marketing-unit__slot--in-content" />
                            <div className="journey-unit" />
                            </div>
                        </div>
                        </div>
                    </li>
                    })
                    : <>No articles to show</>}

                    </ul>
                </div>
                </div>
                <div className="GridItem-buujkM jxgqqn grid--item grid-layout__aside">
                <aside
                    className="PersistentAsideWrapper-deVGrR daRVRt persistent-aside"
                    style={{
                    position: "absolute",
                    top: "1463.75px",
                    height: 900 * Math.min(4, this.state.articles.length) + "px"
                    }}
                    data-testid="PersistentAsideWrapper"
                >
                    <div className="StickyBoxWrapper-jfYBuk jxBcTH sticky-box">
                    <div className="StickyBoxPrimary-dzWDWL cdhYoN sticky-box__primary">
                        <div className="StickyBoxWrapper-jfYBuk jxBcTH sticky-box">
                        <div className="StickyBoxPrimary-dzWDWL cdhYoN sticky-box__primary">
                            <div
                            className="Container-bkChBi byNLHx"
                            >
                            <figure
                                data-testid="cne-interlude-container-right-rail"
                                className="VideoFigure-eayQIa gLxFUg"
                            >
                                <p className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ InterludeHeader-jpfboO iUEiRd hIvbNo dgJnZv">
                                WATCH
                                </p>
                                <figure className="CneVideoEmbedFigure-kCfJjN JUJhd cne-video-embed">
                                    {this.state.featuredVideo?.video_url!==undefined && <video src={this.state.featuredVideo?.video_url} style={{ width: "100%" }} 
                                    autoPlay loop muted controls playsInline></video> }
                                </figure>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    aria-label="Opens in a new window"
                                    href={`/videos/${this.state.featuredVideo.slug}`}
                                    className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BaseLink-eNWuiM InterludeTitleLink-cHlEVd iUEiRd eydVi jKJTKa kyAPDp"
                                >
                                    <p className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ InterludeRightRailTitle-blOXVD iUEiRd cYANYj kBtFGm">
                                        {this.state.featuredVideo?.title}
                                    </p>
                                </a>
                            </figure>
                            </div>
                            <div className="AdWrapper-dQtivb fZrssQ ad ad--rail">
                            <div
                                className="ad__slot ad__slot--rail"
                                data-node-id="u2ucdy"
                            />
                            </div>
                            <div
                            className="ConsumerMarketingUnitThemedWrapper-iUTMTf jssHut consumer-marketing-unit consumer-marketing-unit--display-rail"
                            role="presentation"
                            aria-hidden="true"
                            >
                            <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--display-rail" />
                            <div className="journey-unit" />
                            </div>
                        </div>
                        <div className="StickyBoxPlaceholder-grPmrg dxAvXx" />
                        </div>
                    </div>
                    <div className="StickyBoxPlaceholder-grPmrg dxAvXx" />
                    </div>
                </aside>
                </div>
            </div>
            <div
                className="StickyMidContentAdWrapper-fSBzwl drzyIa ad-stickymidcontent"
            >
                <div className="AdWrapper-dQtivb fZrssQ ad ad--mid-content should-hold-space">
                <div
                    className="ad__slot ad__slot--mid-content"
                    data-node-id="9qy3g8"
                />
                </div>
            </div>
            </div>
        </div>

        {this.state.articles.length > 4 ?
        <div data-page-value={2}>
            <div
            data-testid="RowWrapper"
            className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan GalleryPageChunkRow-eNGlGj jaMwlG"
            >
            <div className="GridWrapper-cAzTTK cVbjEv grid grid-margins grid-items-2 grid-layout--adrail narrow">
                <div className="GridItem-buujkM stRKV grid--item grid-layout__content">
                <div className="">
                    <ul className="GalleryPageSlides-hIivta hlAVzT">

                    {this.state.articles.slice(4, this.state.articles.length - 1).map((item) => {
                    return <li
                        key={item.id}
                        className="gallery__slides__slide"
                    >
                        <div>
                        <div
                            className="GallerySlideWrapper-yxPsh jRixHT product-embed slide-venue slide-hotel impressionTrackingFired"
                            aria-orientation="horizontal"
                        >
                            <figure className="GallerySlideFigure-jhDfjI bGQGmP">
                            <div
                                aria-orientation="horizontal"
                                className="GallerySlideAssetWrapper-jGbftJ jqUBFt"
                            >
                                <div className="GallerySlideAssetContainer-hwdsAZ gNfA-dy">
                                <div className="GallerySlideAssetContainerInner-BTnef fyLCFI">
                                    <span className="SpanWrapper-umhxW jvZaPI responsive-asset GallerySlideResponsiveAsset-bXKMLC lluiXZ GallerySlideResponsiveAsset slide-venue slide-hotel">
                                    <picture
                                        className="ResponsiveImagePicture-cWuUZO dUOtEa GallerySlideResponsiveAsset-bXKMLC lluiXZ GallerySlideResponsiveAsset slide-venue slide-hotel responsive-image"
                                    >
                                        <source
                                        media="(max-width: 767px)"
                                        srcSet={
                                            item.imageURL.replace(/uploads\//, "uploads/400/") +
                                            " 400w, " +
                                            item.imageURL.replace(/uploads\//, "uploads/800/") +
                                            " 800w"
                                          }
                                        sizes="100vw"
                                        />
                                        <source
                                        media="(min-width: 768px)"
                                        srcSet={item.imageURL.replace(/uploads\//, "uploads/1280/") + " 1280w"}
                                        sizes="100vw"
                                        />
                                        <img
                                        alt={item.media_description}
                                        className="ResponsiveImageContainer-eybHBd fptoWY responsive-image__image"
                                        src={item.imageURL.replace(/uploads\//, "uploads/1280/")}
                                        />
                                    </picture>
                                    </span>
                                    <div
                                    className="SocialIconsWrapper-hKMEXV ebzboM social-icons social-icons--overlay GallerySlideSocialIcons-kAFGFd bnSKCQ"
                                    >
                                    <ul
                                        data-testid="socialIconslist"
                                        className="SocialIconsList-cHVTIA euDGtn social-icons__list"
                                    >
                                        <li className="SocialIconsListItem-dembhR gQILrJ social-icons__list-item social-icons__list-item--pinterest social-icons__list-item--has-background social-icons__list-item--overlay">
                                        <a
                                            aria-label="Post to Pinterest"
                                            className="external-link SocialIconExternalLink-huuzOU hnpTAQ social-icons__link social-icons__link--pinterest"
                                            href={"https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(baseUrl + "/article/" + item.slug) + "&media=" + encodeURIComponent(item.imageURL.replace(/uploads\//, "uploads/1280/")) + "&description="}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <div className="SocialIconContainer-huXzUC pMzVC social-icons__icon-container">
                                            <svg
                                                className="SocialIconNetworkIconComponent-cZaKGz dlOAhZ icon icon-pinterest"
                                                focusable="false"
                                                viewBox="0 0 32 32"
                                                width={32}
                                                height={32}
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <title>Pinterest</title>
                                                <path
                                                d="M15.169 18.448C14.793 20.093 14.425 21.678 13.623 22.928C13.377 23.311 13.13 23.793 12.71 24C12.09 20.807 13.387 18.12 13.899 15.436C13.246 14.103 13.652 11.846 15.051 11.59C17.077 11.22 16.543 13.664 16.2 14.8C16.01 15.424 15.671 16.021 15.722 16.705C15.835 18.146 17.648 18.24 18.577 17.497C19.909 16.436 20.295 14.385 20.164 12.7C19.967 10.135 17.062 8.85997 14.496 9.88497C13.173 10.413 11.973 11.628 11.799 13.413C11.709 14.353 11.906 15.104 12.276 15.634C12.331 15.715 12.523 15.857 12.552 16.072C12.61 16.506 12.352 16.974 12.116 17.298C10.802 16.92 10.124 15.741 10.016 14.248C9.76596 10.848 12.558 8.26397 15.841 8.02197C19.348 7.76497 22.126 9.78896 22.384 12.74C22.576 14.933 21.797 17.14 20.561 18.329C19.631 19.221 17.656 20.096 16.041 19.242C15.684 19.052 15.524 18.82 15.169 18.448Z"
                                                fill="black"
                                                />
                                            </svg>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <figcaption className="GallerySlideFigCaptionWrapper-QgqEb jqOVHf GallerySlideCaptionWrapper-cVIrXn gmZcZa slide-venue slide-hotel">
                                <div className="GallerySlideFigCaption-dOeyTg jgkmHh">
                                <div className="GallerySlideCaptionCreditWrapper-houOem lflAou">
                                    {item.media_description}
                                </div>
                                {item.tags?.length && item.tags[0].length > 0 && 
                                <div className="GallerySlideCaptionPreHeaderTwo-juPgiw iBhGZi">
                                    {item.tags.slice(0, 3).map((tag, index, array) => 
                                     <Fragment key={`tag_${item.id}_${index}`}>
                                     <a
                                     href={`/search/?tag=${tag}`}
                                     className="BaseWrap-sc-TwdDQ BaseText-fFHxRE BaseLink-gZIuAp TagCloudLink-ijMAUj hlNbBe doCFOR bWyKqi kfkJOD"
                                   >
                                     <span className="BaseWrap-sc-TwdDQ BaseText-fFHxRE TagCloudName-hILIUU hlNbBe ffobMw jtzwuU">
                                       {tag}
                                     </span>
                                     
                                   </a>
                                   {array.length -1 === index ? "" : <>,&nbsp;</>}
                                   </Fragment>)}
                                </div>
                                }
                                <a
                                    className="external-link"
                                    href={`/article/${item.slug}`}
                                    rel="noopener"
                                    target="_blank"
                                >
                                    <h2 className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ GallerySlideCaptionHed-fiZQOl iUEiRd lcmQdU bPoAxq">
                                    <span className="GallerySlideCaptionHedText-iqjOmM jwPuvZ">
                                        {item.title}
                                    </span>
                                    <svg
                                        className="icon icon-arrow"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        width={24}
                                        height={24}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>Arrow</title>
                                        <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="m22.06 12-9.53-9.53-1.06 1.06 7.718 7.719H2v1.5h17.19l-7.72 7.72 1.06 1.061L22.06 12Z"
                                        />
                                    </svg>
                                    </h2>
                                </a>
                                <div
                                    data-testid="GallerySlideCaptionDekContainer"
                                    className="GallerySlideCaptionDekContainer-hLUdt gSWuis"
                                >
                                    <div className="GallerySlideCaptionDek-cXnbPe cGixxE">
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: item.content }}>
                                        </p>
                                        <em>{item.author}</em>
                                    </div>
                                    </div>
                                    {/* <div className="GallerySlideCaptionOffers-fsTPbf gmClBA">
                                    <div
                                        className="ProductOfferListWrapper-bqpHEj cvoxld product-offer-list"
                                    >
                                        <div className="ProductOfferWrapper-dpcYKd hBwhMM product-offer-list__offer product-offer">
                                        <div
                                            className="ProductOfferCtaBlock-bduvOV fbJiRZ product-offer__cta-block"
                                            id="button_label_Makeareservationatbooking.com"
                                        >
                                            <a
                                            href="https://cna.st/h/meh9fHuiYWLjqEhp45qVrEFhRzvjysLwSgqMhgUi8NJU8fJSP1jffjxaV3N43GZuRLURFJEGbTmvc4oaih5iKkPpw5xwDdZzYfejntm9kwS7YSGR5zDK7b7bedUJU4dCz7bpFNvtf1Ad4sSWDaWvgTTigYTsfizoqc33tr8wY"
                                            rel="sponsored noopener"
                                            target="_blank"
                                            aria-label="Make a reservation at booking.com"
                                            data-buy-button="true"
                                            data-offer-retailer="booking.com"
                                            data-offer-url="https://www.booking.com/hotel/gb/the-gleneagles.en-gb.html"
                                            className="BaseButton-bLlsy ButtonWrapper-xCepQ ljFcOl fRqrDa button button--utility-pair product-offer__buy-button"
                                            data-event-click='{"element":"Button","outgoingURL":"https://cna.st/h/meh9fHuiYWLjqEhp45qVrEFhRzvjysLwSgqMhgUi8NJU8fJSP1jffjxaV3N43GZuRLURFJEGbTmvc4oaih5iKkPpw5xwDdZzYfejntm9kwS7YSGR5zDK7b7bedUJU4dCz7bpFNvtf1Ad4sSWDaWvgTTigYTsfizoqc33tr8wY"}'
                                            data-testid="Button"
                                            type=""
                                            >
                                            <span className="ButtonLabel-cjAuJN hzwRuG button__label">
                                                Make a reservation at booking.com
                                            </span>
                                            </a>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="GallerySlideCaptionCtaBlock-ktoFJX fgjbmf" />
                                    </div> */}
                                </div>
                                </div>
                            </figcaption>
                            </figure>
                        </div>
                        <div
                            className="inline-recirc-wrapper inline-recirc-observer-target-1 viewport-monitor-anchor"
                        />
                        </div>
                    </li>
                    })
                    }
                    </ul>
                </div>
                </div>
                <div className="GridItem-buujkM jxgqqn grid--item grid-layout__aside">
                <div className="StickyBoxWrapper-jfYBuk jxBcTH sticky-box">
                    <div className="StickyBoxPrimary-dzWDWL cdhYoN sticky-box__primary">
                    <div className="AdWrapper-dQtivb fZrssQ ad ad--rail">
                        <div
                        className="ad__slot ad__slot--rail"
                        data-node-id="v9mb3p"
                        />
                    </div>
                    <div
                        className="ConsumerMarketingUnitThemedWrapper-iUTMTf jssHut consumer-marketing-unit consumer-marketing-unit--display-rail"
                        role="presentation"
                        aria-hidden="true"
                    >
                        <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--display-rail" />
                        <div className="journey-unit" />
                    </div>
                    <div
                        data-attr-viewport-monitor=""
                        className="RecircMostPopularWrapper-bRuGTi jkTTgR recirc-most-popular-wrapper viewport-monitor-anchor"
                    >
                        <div
                        className="RecircMostPopularContiner-jiViNn ktrplG recirc-most-popular-sparrow-tracking hide-aside-ad"
                        data-most-popular-id="mod-most-popular-4125"
                        >
                        <div className="RecircMostPopularHeading-iVzhzt ksBxwz">
                            Trending Stories
                        </div>
                        <ul className="RecircMostPopularItems-dCAajf crkceA">
                            {this.state.featuredArticles?.map((item) => (
                            <li key={item.id}>
                            <div
                                className="SummaryItemWrapper-iwvBff koXyjp summary-item summary-item--has-border summary-item--ARTICLE summary-item--no-icon summary-item--text-align-left summary-item--layout-placement-side-by-side summary-item--layout-position-image-left summary-item--layout-proportions-33-66 summary-item--side-by-side-align-center summary-item--side-by-side-image-right-mobile-false summary-item--standard RecircMostPopularSummaryItem-jQxxiw ijnudG"
                                role="button"
                                tabIndex={0}
                            >
                                <div className="SummaryItemAssetContainer-gwhFFH ogpYq summary-item__asset-container">
                                <a
                                    className="SummaryItemImageLink-dshqxb cPpCwE summary-item__image-link summary-item-tracking__image-link"
                                    href={`/article/${item.slug}`}
                                    aria-hidden="true"
                                    tabIndex={-1}
                                    target="_self"
                                >
                                    <span className="SpanWrapper-umhxW jvZaPI responsive-asset SummaryItemResponsiveAsset-hjGIGg gJaBeu summary-item__image">
                                    <div
                                        data-test="aspect-ratio-container"
                                        className="AspectRatioContainer-bJHpJz jgsWEn"
                                    >
                                        <div className="aspect-ratio--overlay-container">
                                            {(item.imageURL.endsWith(".mp4")) ? 
                                            <video width="100%" muted preload="auto" playsInline autoPlay>
                                            <source src={item.imageURL+"#t=0.001"} type="video/mp4" />
                                            </video> :
                                            <picture className="ResponsiveImagePicture-cWuUZO dUOtEa SummaryItemResponsiveAsset-hjGIGg gJaBeu summary-item__image responsive-image">
                                            <img
                                                alt={item.media_description}
                                                className="ResponsiveImageContainer-eybHBd fptoWY responsive-image__image"
                                                src={item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/400/$1.$2")}
                                                loading="lazy"
                                                />
                                            </picture>
                                            }
                                        </div>
                                    </div>
                                    </span>
                                </a>
                                </div>
                                <div className="SummaryItemContent-eiDYMl nLise summary-item__content">
                                <div
                                    className="RubricWrapper-dKmCNX dFwgPI rubric SummaryItemRubric-dguGKN lapGFj summary-item__rubric"
                                >
                                    <span className="RubricName-fVtemz cLxcNi rubric__name">
                                    {(item.tags?.length && item.tags[0].length > 0) ? item.tags[0] : null}
                                    </span>
                                </div>
                                <a
                                    className="SummaryItemHedLink-civMjp rgRxi summary-item-tracking__hed-link summary-item__hed-link right_rail-1"
                                    href={`/article/${item.slug}`}
                                    target="_self"
                                >
                                    <div
                                    data-testid="ClampWrapper"
                                    className="ClampWrapper-kZxfkB hTTNGO clamp SummaryItemHedBase-hiFYpQ jQocOI summary-item__hed"
                                    >
                                    <div className="ClampContent-hilPkr eKRyDY">
                                        <div
                                        className="SummaryItemHedTag-TWblf gxaOcP"
                                        data-testid="SummaryItemHed"
                                        >
                                        {item.title}
                                        </div>
                                    </div>
                                    </div>
                                </a>
                                <div className="SummaryItemBylineWrapper-boCfbi hYsZi summary-item__byline-date-icon">
                                    <div className="SummaryItemBaseByline-fFbXkY eQORWp summary-item__byline">
                                    <div className="summary-item__byline__content">
                                        <div
                                        data-testid="BylinesWrapper"
                                        className="BylinesWrapper-KIudk irTIfE bylines"
                                        >
                                        <p
                                            className="BylineWrapper-jWHrLH daAzYk byline bylines__byline"
                                            data-testid="BylineWrapper"
                                            itemProp="author"
                                            itemType="http://schema.org/Person"
                                        >
                                            <span
                                            itemProp="name"
                                            className="BylineNamesWrapper-jbHncj fuDQVo"
                                            >
                                            <span
                                                data-testid="BylineName"
                                                className="BylineName-kwmrLn cYaBaU byline__name"
                                            >
                                                {item.author}
                                            </span>
                                            </span>
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </li>
                            ))
                            }
                            <li>
                            <div className="AdWrapper-dQtivb fZrssQ RecircMostPopularSummaryItem-jQxxiw hXPTwH ad ad--aside">
                                <div
                                className="ad__slot ad__slot--aside"
                                data-node-id="te7hl"
                                />
                            </div>
                            </li>
                        </ul>
                        <div className="RecircMostPopularFooter-enPjgs knSlyA" />
                        </div>
                    </div>
                    </div>
                    <div className="StickyBoxPlaceholder-grPmrg dxAvXx" />
                </div>
                </div>
            </div>
            <div
                className="StickyMidContentAdWrapper-fSBzwl drzyIa ad-stickymidcontent"
            >
                <div className="AdWrapper-dQtivb fZrssQ ad ad--mid-content should-hold-space">
                <div
                    className="ad__slot ad__slot--mid-content"
                    data-node-id="wow9u8"
                />
                </div>
            </div>
            </div>
        </div>
        : <></>}

        {this.state.articles.length > 5 ?
        <div data-page-value="_end">
            <div
            data-testid="RowWrapper"
            className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan GalleryPageChunkRow-eNGlGj jaMwlG"
            >
            <div className="GridWrapper-cAzTTK cVbjEv grid grid-margins grid-items-2 grid-layout--adrail narrow">
                <div className="GridItem-buujkM stRKV grid--item grid-layout__content">
                <div className="">
                    <ul className="GalleryPageSlides-hIivta hlAVzT">
                    {this.state.articles.slice(this.state.articles.length - 1, this.state.articles.length).map((item) => {
                    return <li
                        key={item.id}
                        className="gallery__slides__slide"
                    >
                        <div>
                        <div
                            className="GallerySlideWrapper-yxPsh jRixHT product-embed slide-venue slide-hotel impressionTrackingFired"
                            aria-orientation="horizontal"
                        >
                            <figure className="GallerySlideFigure-jhDfjI bGQGmP">
                            <div
                                aria-orientation="horizontal"
                                className="GallerySlideAssetWrapper-jGbftJ jqUBFt"
                            >
                                <div className="GallerySlideAssetContainer-hwdsAZ gNfA-dy">
                                <div className="GallerySlideAssetContainerInner-BTnef fyLCFI">
                                    <span className="SpanWrapper-umhxW jvZaPI responsive-asset GallerySlideResponsiveAsset-bXKMLC lluiXZ GallerySlideResponsiveAsset slide-venue slide-hotel">
                                    <picture
                                        className="ResponsiveImagePicture-cWuUZO dUOtEa GallerySlideResponsiveAsset-bXKMLC lluiXZ GallerySlideResponsiveAsset slide-venue slide-hotel responsive-image"
                                    >
                                        <source
                                        media="(max-width: 767px)"
                                        srcSet={
                                            item.imageURL.replace(/uploads\//, "uploads/400/") +
                                            " 400w, " +
                                            item.imageURL.replace(/uploads\//, "uploads/800/") +
                                            " 800w"
                                          }
                                        sizes="100vw"
                                        />
                                        <source
                                        media="(min-width: 768px)"
                                        srcSet={item.imageURL.replace(/uploads\//, "uploads/1280/") + " 1280w"}
                                        sizes="100vw"
                                        />
                                        <img
                                        alt={item.media_description}
                                        className="ResponsiveImageContainer-eybHBd fptoWY responsive-image__image"
                                        src={item.imageURL.replace(/uploads\//, "uploads/1280/")}
                                        />
                                    </picture>
                                    </span>
                                    <div
                                    className="SocialIconsWrapper-hKMEXV ebzboM social-icons social-icons--overlay GallerySlideSocialIcons-kAFGFd bnSKCQ"
                                    >
                                    <ul
                                        data-testid="socialIconslist"
                                        className="SocialIconsList-cHVTIA euDGtn social-icons__list"
                                    >
                                        <li className="SocialIconsListItem-dembhR gQILrJ social-icons__list-item social-icons__list-item--pinterest social-icons__list-item--has-background social-icons__list-item--overlay">
                                        <a
                                            aria-label="Post to Pinterest"
                                            className="external-link SocialIconExternalLink-huuzOU hnpTAQ social-icons__link social-icons__link--pinterest"
                                            href={"https://www.pinterest.com/pin/create/button/?url=" + encodeURIComponent(baseUrl + "/article/" + item.slug) + "&media=" + encodeURIComponent(item.imageURL.replace(/uploads\//, "uploads/1280/")) + "&description="}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <div className="SocialIconContainer-huXzUC pMzVC social-icons__icon-container">
                                            <svg
                                                className="SocialIconNetworkIconComponent-cZaKGz dlOAhZ icon icon-pinterest"
                                                focusable="false"
                                                viewBox="0 0 32 32"
                                                width={32}
                                                height={32}
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <title>Pinterest</title>
                                                <path
                                                d="M15.169 18.448C14.793 20.093 14.425 21.678 13.623 22.928C13.377 23.311 13.13 23.793 12.71 24C12.09 20.807 13.387 18.12 13.899 15.436C13.246 14.103 13.652 11.846 15.051 11.59C17.077 11.22 16.543 13.664 16.2 14.8C16.01 15.424 15.671 16.021 15.722 16.705C15.835 18.146 17.648 18.24 18.577 17.497C19.909 16.436 20.295 14.385 20.164 12.7C19.967 10.135 17.062 8.85997 14.496 9.88497C13.173 10.413 11.973 11.628 11.799 13.413C11.709 14.353 11.906 15.104 12.276 15.634C12.331 15.715 12.523 15.857 12.552 16.072C12.61 16.506 12.352 16.974 12.116 17.298C10.802 16.92 10.124 15.741 10.016 14.248C9.76596 10.848 12.558 8.26397 15.841 8.02197C19.348 7.76497 22.126 9.78896 22.384 12.74C22.576 14.933 21.797 17.14 20.561 18.329C19.631 19.221 17.656 20.096 16.041 19.242C15.684 19.052 15.524 18.82 15.169 18.448Z"
                                                fill="black"
                                                />
                                            </svg>
                                            </div>
                                        </a>
                                        </li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <figcaption className="GallerySlideFigCaptionWrapper-QgqEb jqOVHf GallerySlideCaptionWrapper-cVIrXn gmZcZa slide-venue slide-hotel">
                                <div className="GallerySlideFigCaption-dOeyTg jgkmHh">
                                <div className="GallerySlideCaptionCreditWrapper-houOem lflAou">
                                    {item.media_description}
                                </div>
                                {item.tags?.length && item.tags[0].length > 0 && 
                                <div className="GallerySlideCaptionPreHeaderTwo-juPgiw iBhGZi">
                                    {item.tags.slice(0, 3).map((tag, index, array) => 
                                     <>
                                     <a
                                     key={`tag_${item.id}_${index}`}
                                     href={`/search/?tag=${tag}`}
                                     className="BaseWrap-sc-TwdDQ BaseText-fFHxRE BaseLink-gZIuAp TagCloudLink-ijMAUj hlNbBe doCFOR bWyKqi kfkJOD"
                                   >
                                     <span className="BaseWrap-sc-TwdDQ BaseText-fFHxRE TagCloudName-hILIUU hlNbBe ffobMw jtzwuU">
                                       {tag}
                                     </span>
                                     
                                   </a>
                                   {array.length -1 === index ? "" : <>,&nbsp;</>}
                                   </>)}
                                </div>
                                }
                                <a
                                    href={"/article/" + item.slug}
                                    target="_blank"
                                >
                                    <h2 className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ GallerySlideCaptionHed-fiZQOl iUEiRd lcmQdU bPoAxq">
                                    <span className="GallerySlideCaptionHedText-iqjOmM jwPuvZ">
                                        {item.title}
                                    </span>
                                    <svg
                                        className="icon icon-arrow"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        width={24}
                                        height={24}
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>Arrow</title>
                                        <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="m22.06 12-9.53-9.53-1.06 1.06 7.718 7.719H2v1.5h17.19l-7.72 7.72 1.06 1.061L22.06 12Z"
                                        />
                                    </svg>
                                    </h2>
                                </a>
                                <div
                                    data-testid="GallerySlideCaptionDekContainer"
                                    className="GallerySlideCaptionDekContainer-hLUdt gSWuis"
                                >
                                    <div className="GallerySlideCaptionDek-cXnbPe cGixxE">
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: item.content }}>
                                        </p>
                                        <em>{item.author}</em>
                                    </div>
                                    </div>
                                    {/* <div className="GallerySlideCaptionOffers-fsTPbf gmClBA">
                                    <div
                                        className="ProductOfferListWrapper-bqpHEj cvoxld product-offer-list"
                                    >
                                        <div className="ProductOfferWrapper-dpcYKd hBwhMM product-offer-list__offer product-offer">
                                        <div
                                            className="ProductOfferCtaBlock-bduvOV fbJiRZ product-offer__cta-block"
                                            id="button_label_MakeareservationatRelaisChateaux"
                                        >
                                            <a
                                            href="https://cna.st/h/meh9fHuiYWLjqEhp45qVrEFhRzvjysLwSgqMhgUi8NJU8fJSP1jfaBvhvrQMZnUVZPcSnQh9aZXZdYsAGfZLT38KChq5PKD8bNGkJkVcxgwdv3Jzjf6z6QcgR4oN3vph97AsVrCdc6yHoXZUSyHuf9AmYSvX4B6D2tQVhArbp"
                                            rel="sponsored noopener"
                                            target="_blank"
                                            aria-label="Make a reservation at Relais Chateaux"
                                            data-buy-button="true"
                                            data-offer-retailer="Relais Chateaux"
                                            data-offer-url="https://www.relaischateaux.com/gb/hotel/hotel-union-oye/"
                                            className="BaseButton-bLlsy ButtonWrapper-xCepQ ljFcOl fRqrDa button button--utility-pair product-offer__buy-button"
                                            data-event-click='{"element":"Button","outgoingURL":"https://cna.st/h/meh9fHuiYWLjqEhp45qVrEFhRzvjysLwSgqMhgUi8NJU8fJSP1jfaBvhvrQMZnUVZPcSnQh9aZXZdYsAGfZLT38KChq5PKD8bNGkJkVcxgwdv3Jzjf6z6QcgR4oN3vph97AsVrCdc6yHoXZUSyHuf9AmYSvX4B6D2tQVhArbp"}'
                                            data-testid="Button"
                                            type=""
                                            >
                                            <span className="ButtonLabel-cjAuJN hzwRuG button__label">
                                                Make a reservation at Relais
                                                Chateaux
                                            </span>
                                            </a>
                                        </div>
                                        </div>
                                    </div>
                                    <div className="GallerySlideCaptionCtaBlock-ktoFJX fgjbmf" />
                                    </div> */}
                                </div>
                                </div>
                            </figcaption>
                            </figure>
                        </div>
                        <div
                            className="inline-recirc-wrapper inline-recirc-observer-target-4 viewport-monitor-anchor"
                        />
                        </div>
                    </li>
                    })
                    }
                    </ul>
                </div>
                </div>
                <div className="GridItem-buujkM jxgqqn grid--item grid-layout__aside">
                <div className="StickyBoxWrapper-jfYBuk jxBcTH sticky-box">
                    <div className="StickyBoxPrimary-dzWDWL cdhYoN sticky-box__primary">
                    <div className="AdWrapper-dQtivb fZrssQ ad ad--rail">
                        <div
                        className="ad__slot ad__slot--rail"
                        data-node-id="va7udi"
                        />
                    </div>
                    <div
                        className="ConsumerMarketingUnitThemedWrapper-iUTMTf jssHut consumer-marketing-unit consumer-marketing-unit--display-rail"
                        role="presentation"
                        aria-hidden="true"
                    >
                        <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--display-rail" />
                        <div className="journey-unit" />
                    </div>
                    </div>
                    <div className="StickyBoxPlaceholder-grPmrg dxAvXx" />
                </div>
                </div>
            </div>
            </div>
        </div>
        : <></>}

        </div>
        <div className="GridWrapper-cAzTTK cVbjEv grid grid-margins grid-items-2 grid-layout--adrail narrow">
        <div className="GridItem-buujkM stRKV grid--item grid-layout__content">
            <div className="GalleryPageTextBlock-lkymFq hJvbmx">
            <aside
                className="PaywallInlineBarrierWrapper-iBnuqk lfXXa-D"
                data-testid="PaywallInlineBarrierWrapper"
            >
                <div
                className="ConsumerMarketingUnitThemedWrapper-iUTMTf jssHut consumer-marketing-unit consumer-marketing-unit--paywall-inline-barrier"
                role="presentation"
                aria-live="polite"
                aria-hidden="true"
                >
                <div className="consumer-marketing-unit__slot consumer-marketing-unit__slot--paywall-inline-barrier" />
                <div className="journey-unit" />
                </div>
            </aside>
            </div>
        </div>
        </div>
        <div
        data-testid="RowWrapper"
        data-journey-hook="row-content"
        className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan MultiPackageBaseRow-gSnwPR MultiPackageRow-cnuYUr faNxPo cFFpMM"
        />
        <div
        className="ContentFooterWrapper-jVNdRG dTJkpP GalleryPageContentFooter-fcWMSu ehspjU"
        >
        <div
            data-testid="RowWrapper"
            className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan"
        >
            <div className="GridWrapper-cAzTTK cVbjEv grid grid-margins grid-items-2 grid-layout--adrail narrow">
            <div className="GridItem-buujkM stRKV grid--item grid-layout__content">
                <div
                data-testid="TagCloudWrapper"
                className="TagCloudWrapper-gGgndx eQNWYa ContentFooterTagCloud-krQmRG esZLGh"
                >
                <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ TagCloudSectionHeader-cOforY iUEiRd fCShZo kQQRmu">
                    Topics
                </span>
                <a
                    href="/cruises"
                    className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BaseLink-eNWuiM TagCloudLink-kvjZFu iUEiRd hiiKEl jDVxPo ldGMWn"
                >
                    <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ TagCloudName-eAUnLd iUEiRd gkRRez bUkXwu">
                    Cruises
                    </span>
                </a>
                <a
                    href="/unique-hotels"
                    className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BaseLink-eNWuiM TagCloudLink-kvjZFu iUEiRd hiiKEl jDVxPo ldGMWn"
                >
                    <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ TagCloudName-eAUnLd iUEiRd gkRRez bUkXwu">
                    Unique Hotels
                    </span>
                </a>
                <a
                    href="/adventure-travel"
                    className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BaseLink-eNWuiM TagCloudLink-kvjZFu iUEiRd hiiKEl jDVxPo ldGMWn"
                >
                    <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ TagCloudName-eAUnLd iUEiRd gkRRez bUkXwu">
                    Adventure Travel
                    </span>
                </a>
                <a
                    href="/world/europe"
                    className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BaseLink-eNWuiM TagCloudLink-kvjZFu iUEiRd hiiKEl jDVxPo ldGMWn"
                >
                    <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ TagCloudName-eAUnLd iUEiRd gkRRez bUkXwu">
                    Europe
                    </span>
                </a>
                </div>
            </div>
            </div>
        </div>
        <div
            data-testid="ContentFooterBottom"
            className="ContentFooterBottom-jwWZfC kcbyiW"
        >
            <div className="content-bottom-anchor" />
            <div
            data-attr-viewport-monitor=""
            className="recirc-list-wrapper viewport-monitor-anchor"
            >
            <div
                data-testid="RowWrapper"
                className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan"
            >
                <aside className="RecircListTextOverlayWrapper-EAayj ikNPKA ContentFooterRelated-kEBiFe kVUuwq">
                <div className="recirc-list__container">
                    <header className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ RecircListTextOverlayHeading-icEzDE iUEiRd jwkiwZ jntJfi">
                    More from Vacation Muse
                    </header>
                    <ul className="RecircListTextOverlayItems-kknlpG lpbERO recirc-list__items hide-read-more-ad">
                    {this.state.readMoreArticles?.map((item) => {
                          return <>
                          <li className="RecircListTextOverlayItem-dLTGJZ cZQSMJ">
                        <a
                          className="OverlaySummaryItemWrapper-jydJgB eETcvP summary-item-tracking__image-link bottom_recirc-1"
                          href={"/article/" + item.slug}>
                         <span className="SpanWrapper-umhxW jvZaPI responsive-asset OverlaySummaryItemResponsiveAsset-gAMhYK ejjgTC">
                            {(item.imageURL.endsWith(".mp4")) ? 
                            <video width="100%" muted preload="auto" playsInline autoPlay>
                            <source src={item.imageURL+"#t=0.001"} type="video/mp4" />
                            </video> :
                            <picture className="ResponsiveImagePicture-cWuUZO dUOtEa OverlaySummaryItemResponsiveAsset-gAMhYK ejjgTC responsive-image">
                              <source
                                media="(max-width: 767px)"
                                srcSet=""
                                sizes="100vw"
                              />
                              <source
                                media="(min-width: 768px)"
                                srcSet=""
                                sizes="100vw"
                              />
                              <img
                                alt={item.media_description}
                                className="ResponsiveImageContainer-eybHBd fptoWY responsive-image__image"
                                src={item.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/800/$1.$2")}
                                loading="lazy"
                              />
                            </picture>}

                          </span>
                          <div className="OverlaySummaryItemContent-ddGOWz hnkIRf">
                            <div className="OverlayItemContentWrapper-fOyeUo hMTDtk">
                              {item.tags != null && item.tags[0].length > 0 && item.tags.map((tag) =>
                              <div className="RubricWrapper-dKmCNX jZUdta rubric rubric--with-bg BaseWrap-sc-gjQpdd BaseText-ewhhUZ OverlaySummaryItemRubric-bFqsqT iUEiRd fEqmdk ifFWjO">
                              <span className="RubricName-fVtemz cLxcNi rubric__name">
                                  {tag}
                                </span>
                              </div>)}
                              <div
                                data-testid="ClampWrapper"
                                className="ClampWrapper-kZxfkB hTTNGO clamp"
                              >
						       <div className="ClampContent-hilPkr fMMBZW">
                                  <div className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ OverlaySummaryItemHed-cljMtD iUEiRd cAfzZg gfUqBY">
                                    <p>{item.title}</p>
                                  </div>
                                </div>
                              </div>
                              <div
                                data-testid="BylinesWrapper"
                                className="BylinesWrapper-KIudk irTIfE bylines OverlaySummaryItemBylines-bogcUE odQTH"
                              >
                                <p
                                    className="BylineWrapper-jWHrLH daAzYk byline bylines__byline"
                                    data-testid="BylineWrapper"
                                    itemProp="author"
                                    itemType="http://schema.org/Person"
                                >
                                    <span className="BaseWrap-sc-gjQpdd BaseText-ewhhUZ BylinePreamble-iJolpQ iUEiRd jyeccG gnILss byline__preamble">By </span>
                                    <span
                                    itemProp="name"
                                    className="BylineNamesWrapper-jbHncj fuDQVo"
                                    >
                                    <span
                                        data-testid="BylineName"
                                        className="BylineName-kwmrLn cYaBaU byline__name"
                                    >
                                        {item.author}
                                    </span>
                                    </span>
                                </p>
                              </div>
                            </div>
						  </div>
                        </a>
                      </li>
                      </>
                      })}
                    </ul>
                </div>
                </aside>
            </div>
            </div>
            <div
            data-testid="RowWrapper"
            className="BaseWrap-sc-gjQpdd RowWrapper-UmqTg iUEiRd HEhan"
            >
            <div className="AdWrapper-dQtivb fZrssQ ad ad--footer should-hold-space">
                <div className="ad__slot ad__slot--footer" data-node-id="feiv6c" />
            </div>
            </div>
        </div>
        </div>
    </article>
    </main>
    );
  }
}

                 