import { Component } from "react";
import { Video } from "../../app/models/video";
import Pagination from "../pagination";
import VideoPageListItem from "./videoPageListItem";

export default class VideoList extends Component<any, any> {
  state = {
    videoPageItems: Array<Video>(),
    currentPageItems: Array<Video>(),
    currentPage: 1,
    horizontal: true,
    pageSize: 6
  };
  SwitchFunction: any;

  constructor(props: any) {
    super(props);

    if('PageSize' in props)
      this.state.pageSize = props.PageSize;

    this.SwitchFunction = props.SwitchFunction;

    this.state.videoPageItems = props.Videos;

  }

  updateListItems() {
    const firstPageIndex = (this.state.currentPage - 1) * this.state.pageSize;
    const lastPageIndex = firstPageIndex + this.state.pageSize;
    var currentItems = this.state.videoPageItems.slice(
      firstPageIndex,
      lastPageIndex
    );

    this.setState({ currentPageItems: currentItems });
  }

  componentDidMount() {
    this.updateListItems();
  }

  componentWillUnmount() {}

  render() {
    return (
      <>
        
        <div className="destinations__grid">
          {this.state.currentPageItems.map((object) => (
            <VideoPageListItem
              key={object.id}
              id={object.id}
              title={object.title}
              slug={object.slug}
              src={object.thumbnail}
              description={object.description}
              SwitchFunction={this.SwitchFunction}
            />
          ))}

        </div>
        
        <Pagination
            className="pagination-bar"
            currentPage={this.state.currentPage}
            totalCount={this.state.videoPageItems.length}
            pageSize={this.state.pageSize}
            horizontal={this.state.horizontal}
            onPageChange={(page: any) => {
              this.setState({ currentPage: page }, this.updateListItems);
            }}
          />
      </>
    );
  }
}
