import React, { Component } from 'react';

export default class SubscribeModal extends Component<any,any> {
  render(){
    return(
      <div>
          <div className="modalBackground" onClick={this.props.closeFunction}>
            <div className="modalWrapper" ref={this.props.wrapperRef}>
              <video className="modalImg" loop autoPlay muted playsInline>
                <source src="/assets/resort-complex.mp4" type="video/mp4" />
              </video>
              <div className="modalContent">
                  <h1>Never stop exploring</h1>
                  <p>Get travel news directly to your inbox</p>
                  <input type="text" placeholder="example@email.com" style={{marginBottom:20, height:30,width:240}}/>
                  <button>Join now.</button>
              </div>
              <button className="closeModalButton" onClick={this.props.closeFunction} ref={this.props.closeButtonRef}>[x]</button>
            </div>
          </div>
      </div>
    );
  }
}


