import axios, { AxiosResponse, AxiosError } from "axios";
import React, { Component } from "react";
import SearchResultItem from "./searchResultItem";
import { setTitle, getTitleForStaticPage } from "../../utils/titleHelpers";

export class SearchResult {
  id?: number;
  title: string;
  slug: string;
  rubric: string;
  author: string
  date: string;
  description:string;
  imageURL: string;
  
  constructor(props: any) {
    this.title = props.title;
    this.slug = props.slug;
    this.rubric = props.rubric;
    this.author = props.author;
    this.date = props.date;
    this.description = props.description;
    this.imageURL = props.imageURL;
  }
}

export default class Search extends Component {
  state = {
    searchTerm: "",
    results: Array<SearchResult>(),
  };
  counter: number;

  constructor(props: any) {
    super(props)

    this.counter = 0;

    let searchTerm = (new URLSearchParams(window.location.search)).get("search")
    if(searchTerm)
      this.state.searchTerm = searchTerm;

  }

  componentDidMount(): void {
    this.loadDestinations();
  }

  loadDestinations() {

    axios
      .get('/api/articles', {
        params: {
          search: this.state.searchTerm
        }
      })
      .then((response: AxiosResponse) => {
        this.setState({results: response.data});
      })
      .catch((error: AxiosError<any>) => {
        console.log(error);
        if('message' in error.response?.data)
          alert('Failed to fetch the article: ' + error.response?.data.message);
      })
      .then(() => { });
 
  }
  
  render() {
    setTitle(getTitleForStaticPage());
    return (

      <div data-reactroot className="page-search page-container container">
        <link rel="stylesheet" type="text/css" href="/assets/search.css"/>

        <div className="details-search">
          <h1 className="details-search__hed">Search</h1>
          <form className="details-search__form"><label className="details-search__label" htmlFor="details-search__input">Search</label><button className="details-search__button gq-icon gq-icon-search" /><input type="text" className="details-search__input" id="details-search__input" name="search" placeholder="Search" defaultValue={this.state.searchTerm} /></form>
          <p className="details-results details-search__details-results"><span className="details-results__label">Displaying </span><span className="details-results__count">{this.state.results.length}</span><span className="details-results__separator"> of </span><span className="details-results__total">{this.state.results.length}</span></p>
        </div>
        <div className="river-full-width">
          <div className="river-full-width__river">
          {this.state.results.map((object) => (
            <SearchResultItem
              key={object.id}
              isEven={this.counter++ % 2 === 0}
              title={object.title}
              articleLink={"/article/" + object.slug}
              imageURL={object.imageURL.replace(/\/uploads\/(.*?)\.(jpeg|png|jpg|gif|svg)/, "/uploads/800/$1.$2")}
              rubric={object.rubric}
              description={object.description}
              author={object.author}
              date={object.date}
            />
          ))}

          </div>
          <div className="river-full-width__view-more"><button className="river-full-width__view-more-button">View More</button></div>
        </div>

      </div>
    );
  }
}