import { Component } from "react";
import { Link } from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <footer className="content-info">
                <div id="main-footer">
                    <div className="main-footer-wrapper container">
                        <div className="row">
                            <div className="mnav-left">
                                <div className="main-footer-logo" style={{ backgroundImage: "url(/images/logo.svg)" }}></div>

                                <ul className="col-sm-4 hidden-xs">
                                    <li><Link to="/">About Us</Link></li>
                                    <li><Link to="/">Our Team</Link></li>
                                </ul>
                                <ul className="col-sm-4 hidden-xs">
                                    <li><Link to="/">Contact Us</Link></li>
                                    <li><Link to="/">Advertising</Link></li>
                                </ul>

                                <ul className="col-xs-6 hidden-md hidden-lg">
                                    <li><Link to="/">About Us</Link></li>
                                    <li><Link to="/">Our Team</Link></li>
                                </ul>
                                <ul className="col-xs-6 hidden-md hidden-lg">
                                    <li><Link to="/">Contact Us</Link></li>
                                    <li><Link to="/">Advertising</Link></li>
                                </ul>
                            </div>

                            <div className="mnav-right">
                                <div className="newsletter-signup">
                                    <span className="icon"></span>NEWSLETTER SIGN UP</div>
                                <div className="main-footer-subscribe">
                                    <div className="newsletter-close hidden-sm hidden-md hidden-lg"><img src="/images/mnav/close-white@3x.png" alt="Close" width="15" height="15" srcSet="/images/mnav/close-white@2x.png 2x, /images/mnav/close-white@3x.png 3x" className="close" /></div>
                                    <div className="stay-updated">STAY UPDATED</div>
                                    <div className="footer-subscribe-error"></div>
                                    <div className="footer-subscribe-success">{/*                                         
                                        <strong>Thank you for signing up!</strong> <span className="br1">Check your</span> <span className="br2">email for an email confirmation.</span> */}</div>
                                    <form action="/services/newsletter/subscribe" method="POST">
                                        <input type="hidden" name="DESTID" value="0" />
                                        <input type="hidden" name="MID" value="1425298" />
                                        <input type="hidden" name="SEND_CONFIRM" value="true" />
                                        <input type="hidden" name="SUBTYPE" value="create" />
                                        <input type="hidden" name="REF" value="" id="formRef" />

                                        <fieldset className="email">
                                            <input type="text" name="EMAIL" placeholder="Enter your email" />
                                        </fieldset>
                                        <fieldset className="zip jsDesktopToggle">
                                            <input type="text" name="PC" placeholder="Zipcode" />
                                        </fieldset>
                                        <fieldset className="lists jsDesktopToggle">
                                            <div>
                                                <p>
                                                    <label htmlFor="subscribe-footer-all">I want emails from Vacation Muse. I can unsubscribe any time using the unsubscribe link at the end of all emails. After registering, I can manage my newsletter subscriptions by visiting my Profile Settings page.</label>
                                                </p>
                                            </div>
                                            <input type="hidden" name="LID[]" value="561459" />
                                            <input type="hidden" className="optionalCheck" name="LID[]" value="590059" />
                                            <input type="hidden" className="optionalCheck" name="LID[]" value="561458" />
                                            <input type="hidden" className="optionalCheck" name="LID[]" value="686765" />
                                            <input type="hidden" className="optionalCheck" name="LID[]" value="776538" />
                                        </fieldset>
                                        <fieldset className="submit">
                                            <input type="submit" value="SUBSCRIBE" className="btn" />
                                        </fieldset>
                                        <div className="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer-lock-up">
                    <div className="lock-up-wrapper container">
                        <ul>
                            <li className="endorsement">
                                <strong>Vacation Muse</strong><br />
                                Use of this site indicates your consent to the Terms of Use. Copyright &copy; {new Date().getFullYear()}.<br />
                                All rights reserved.
                            </li>
                            <li className="legal">
                                <Link to="/">Terms of Use</Link>
                                <span className="pipe">|</span>
                                <Link to="/">Privacy Policy</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>

        )
    }
}