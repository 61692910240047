import React, { Component } from "react";
import { setTitle, getTitleForStaticPage } from "../utils/titleHelpers";
import { Link } from "react-router-dom";

export default class Subscribe extends Component{
    state={}
    render()
    {
        setTitle(getTitleForStaticPage());
        return(
<main id="main-content" tabIndex={-1}
    className="BaseWrap-sc-TwdDQ BasePageMainContent-cLpaLc hlNbBe XgDEs page__main-content">
    <link rel="stylesheet" href="/subscriptionPageInline.css" />
    <link rel="stylesheet" href="/articlePage.css?v2" />
    <div className="grid grid-items-0 newsletter-subscribe-page__grid">
        <div data-testid="UtilityCardWrapper"
            className="BaseWrap-sc-TwdDQ UtilityCardWrapper-cLUmSv hlNbBe ewCaHi utility-card grid--item newsletter-subscribe-page__form">
            <div className="BaseWrap-sc-TwdDQ UtilityCardContent-eArNGV hlNbBe utility-card__content">
                <header
                    className="BaseWrap-sc-TwdDQ UtilityLedeHeader-ffHAOv hlNbBe kKWvNc utility-lede newsletter-subscribe-page__lede"
                    aria-label="UtilityPageHeader">
                    <div className="BaseWrap-sc-TwdDQ UtilityLedeWrapper-eVYAXL hlNbBe cKTMIN">
                        <h1 data-testid="UtilityLedeHedText"
                            className="BaseWrap-sc-TwdDQ BaseText-fFHxRE UtilityLedeHedText-fqsOWE hlNbBe hNGhbn gFXIta">Vacation Muse Weekly</h1>
                        <h2 data-testid="UtilityLedeDekText"
                            className="BaseWrap-sc-TwdDQ BaseText-fFHxRE UtilityLedeDekText-bGGLyT hlNbBe bQQvIv bSOLBc">
                            <span>Love to travel?</span>
                            <p>
                              Get FREE email communications from Vacation Muse, covering
                              must-see travel destinations, expert trip planning advice, and
                              travel inspiration to fuel your passion.
                            </p>
                        </h2>
                    </div>
                </header>
                <div data-testid="NewsletterSubscribeFormWrapper"
                    className="BaseWrap-sc-TwdDQ NewsletterSubscribeFormWrapper-grkoYw hlNbBe Pajsy newsletter-subscribe-form newsletter-subscribe-page__sign-up-form"
                   
                   
                   
                   >
                    <form className="form-with-validation NewsletterSubscribeFormValidation-drIBpk NpXKf"
                        id="newsletter-subscribe" name="newsletter-subscribe" noValidate method="POST"><span
                            className="BaseWrap-sc-TwdDQ TextFieldWrapper-fzYiBs jTkrfY fuoAIo text-field"
                            data-testid="TextFieldWrapper__email"><label
                                className="BaseWrap-sc-TwdDQ BaseText-fFHxRE TextFieldLabel-gQstMt hlNbBe bQQvIv kOSyxr text-field__label text-field__label--single-line"
                                htmlFor="newsletter-subscribe-text-field-email" data-testid="TextFieldLabel__email">
                                <div
                                    className="BaseWrap-sc-TwdDQ TextFieldLabelText-iYEHIo hlNbBe hUNOZH text-field__label-text">
                                    Enter your e-mail address</div>
                                <div className="BaseWrap-sc-TwdDQ TextFieldInputContainer-fvhZBG hlNbBe kPytB"><input
                                        type="email" aria-describedby="privacy-text" aria-invalid="false"
                                        id="newsletter-subscribe-text-field-email" required name="email"
                                        placeholder="Your e-mail address"
                                        className="BaseInput-jNbqqb TextFieldControlInput-dlsycK kWtEMU jzwfDq text-field__control text-field__control--input"
                                        data-testid="TextFieldInput__email" defaultValue=""/></div>
                            </label><button
                                className="BaseButton-bbuSKr ButtonWrapper-dOBkMp phezu eSIWkO button button--utility TextFieldButton-hFlhbh dhkWxj text-field__button"
                                data-testid="Button"
                                type="submit"><span className="ButtonLabel-eAXKGY hsjQvW button__label">Sign Up
                                    Now</span></button></span>
                        <div id="privacy-text" tabIndex={-1}
                            className="BaseWrap-sc-TwdDQ NewsletterSubscribeFormDisclaimer-dhdKhI hlNbBe kKvJLb"><span>
                                <p>By signing up you agree to our <Link
                                        to="/">Terms of Use</Link> and <Link
                                        to="/">Privacy Policy &amp; Cookie
                                        Statement</Link>.</p>
                            </span></div>
                    </form>
                </div>
            </div>
        </div>
        <div className="grid--item newsletter-subscribe-page__image-container"><span
                className="BaseWrap-sc-TwdDQ SpanWrapper-kGyDFb hlNbBe iDbWuK responsive-asset newsletter-subscribe-page__responsive-image">
                <picture
                    className="ResponsiveImagePicture-jJiTGL jyYsQg newsletter-subscribe-page__responsive-image responsive-image">
                    <img alt="" className="responsive-image__image"
                        src="https://media.gq.com/photos/60100f9b600d5e5958c663da/16:9/w_1600/GQ%2520Daily.png"
                        srcSet="https://media.gq.com/photos/60100f9b600d5e5958c663da/16:9/w_1600/GQ%2520Daily.png 1600w, https://media.gq.com/photos/60100f9b600d5e5958c663da/16:9/w_1280/GQ%2520Daily.png 1280w, https://media.gq.com/photos/60100f9b600d5e5958c663da/16:9/w_1024/GQ%2520Daily.png 1024w"
                        sizes="100vw"/>
                </picture>
            </span></div>
    </div>
</main>
         );
    }
}