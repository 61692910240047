export class Article {
    id!: number;
    title!: string;
    slug!: string;
    subtitle!: string;
    content!: string;
    imageURL!: string;
    author!: string;
    type!: number;
    created_at?: Date | null;
    updated_at?: Date | null;
    header_carousel!: CarouselItem[];
    bottom_carousel!: CarouselItem[];
    tags?: string[];
    media_description?: string;
    status!: number;
}

class CarouselItem {
    url!: string;
    label!: string;
    type!:string;
}